
    @media only screen and (max-width: 550px) {
        .text-fix{
            margin-top: -10px;
            display: block;
            word-break: break-all;
            margin-left: 10px;
            font-size: 13px;
        }
    }
    /* @media only screen and (max-width: 550px) {
        .innerQuestionBox input[type=radio].css-checkbox + label.c-handler {
            padding-left: 34px;
            padding-bottom: 10px;
            display: inline;
            background-size: 505px 600px;
        }
    } */

        /* .innerQuestionBox input[type=radio].css-checkbox + label.c-handler {
            padding-left: 32px !important;
            padding-bottom: 10px !important;
            min-height: 35px !important;
            display: inline !important;
            background-repeat: no-repeat !important;
            background-position: -470px -92px !important;
            vertical-align: middle !important;
            cursor: pointer !important;
            background-size: 500px 600px !important;
        } */

        .spacer{
            margin-top:10px !important;
            display: flex;
            align-items: center;
            margin: 0;
        }

        .text-fix{
            display: block;
           /* word-break: break-all;*/
            width: calc(100% - 60px);
            /* margin-left: 51px;
            margin-top: -41px; */
        }

        label.c-handler{
            margin: 0px;
        }