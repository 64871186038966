div.alert.alert-danger.alert-dismissable{
    position: fixed !important;
    left:25% !important;
    height: 50px;
}

div.alert.alert-danger.alert-dismissable button.close {
    width: 20px;
    height: 20px;
}

div.alert.alert-danger.alert-dismissable button.close span:first-child{
    float: right;
}