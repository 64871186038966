/*** Additional Css for adjustment ***/

#cross-button {
    color: black;
    background-color: white;
    border-color: white;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
    /*margin-right:5px;*/
    margin-left: 94%;
    font-size: 26px !important;
}

.explanation_box .eleRowspop {
    color: #0f6fb4;
    font-size: 20px !important;
    margin-bottom: 0;
    padding: 15px 0;
    text-align: left;
    width: 100%;
    margin-top: -59px;
    display: inherit;
    font-weight: 700 !important;
}

.explanation_box video {
    max-width: 668px !important;
    margin-left: 40px;
}

.arabic video {
    margin-right: 33px;
}

.lp_explanation {
    font-family: Verdana, Geneva, sans-serif !important;
}

#Play i {
    color: #2196f3;
}

#prac-assignment .fieldBoxFullWidth {
    width: 100% !important;
}

#prac-assignment label.css-label1 {
    /*background-image: none;*/
}

#prac-assignment .lastQuesRedirect {
    bottom: 50px !important;
    height: 80px !important;
}

#prac-assignment #mainQuestionView .answerTextArea {
    padding: 20px;
    width: 98%;
    height: 200px;
    margin: 10px;
}

#prac-assignment .submitAnswer {
    width: 100px;
    text-align: center;
    position: absolute;
    margin-left: -50px;
    font-size: 12px;
}

#prac-assignment .forPracticeOnly {
    margin-bottom: 15px;
    margin-left: 35px;
}

#prac-assignment .infoIcon {
    display: inline-block;
    padding: 10px;
}

#prac-assignment .filled {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    border-radius: 10px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

#prac-assignment #test_report_modal .modal-dialog {
    width: 68vw !important;
    height: 85vh;
    overflow: auto;
}

#prac-assignment #count_modal .modal-dialog {
    width: 350px !important;
    margin: 150px auto !important;
}

#prac-assignment #all-units div.SubjectBox {
    padding-left: 0px;
}

#prac-assignment #all-units div.SubjectBox .limitFilters .fieldBox>div {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

#prac-assignment #all-units div.SubjectBox .limitFilters .fieldBox>div.active {
    border-bottom: 1px solid #ddd;
    background: #c5e1f7 none repeat scroll 0 0;
    cursor: pointer;
}

#prac-assignment #all-units div.SubjectBox .limitFilters .fieldBox div label {
    padding-left: 30px;
}

#prac-assignment .filterBlockss .maincolblusr {
    display: inline-block !important;
}

#no_activity_selected p {
    margin: 50px;
    font-size: 20px !important;
    text-align: center;
}

#no_activity_selected p a {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid;
    margin-top: 20px !important;
}

#prac-assignment h3.ttme, #prac-assignment h3.ccard, #prac-assignment h3.sscore {
    margin: 0;
}

#prac-assignment span.bluetxta {
    margin: 5px 0 0px;
}

#prac-assignment a.greenbtn, #prac-assignment a.orngbtn {
    padding: 5px 10px;
    width: 140px;
    text-align: center;
}

#prac-assignment a.greenbtn.active {
    background: #3c873c;
    color: white;
}

#prac-assignment a.orngbtn.active {
    background: #ff7044;
    color: white;
}

#prac-assignment .brdgr, #prac-assignment .brdrnam {
    height: 400px;
}

@media only screen and (min-width: 769px) {
    #prac-assignment .brdgr, #prac-assignment .brdrnam {
        max-width: 80%;
        margin: 0px 20% 50px 15%;
    }
}

#prac-assignment .cntrfonbig {
    padding: 25px 25px;
    min-height: 200px;
    text-align: center;
    vertical-align: middle;
    /*padding: 8%;*/
}

#prac-assignment .brdrnam .eleRows {
    padding: 25px 0;
    margin-bottom: 0;
}

#prac-assignment .flashcard-word {
    font-size: 2.5em !important;
    padding: 0px;
    text-align: center;
    color: #0f6fb4;
    vertical-align: middle;
    word-break: break-word;
    padding-top: 82px;
}

#prac-assignment #mainQuestionView {
    margin-bottom: 80px;
    margin-top :13px
}

@media only screen and (max-width: 415px) {
    #prac-assignment #mainQuestionView {
        margin-bottom: 160px;
    }
    #prac-assignment .brdgr, #prac-assignment .brdrnam {
        height: 290px !important;
        overflow-x: hidden;
        overflow-y: auto;
    }
    #prac-assignment .front .cntrfonbig {
        padding: 25px 25px;
        min-height: 200px;
        text-align: center;
        vertical-align: middle;
        padding-inline: auto;
        /*padding: 8%;*/
    }
    #prac-assignment .back .cntrfonbig {
        padding: 25px 25px;
        min-height: 200px;
        text-align: center;
        vertical-align: middle;
        padding-inline: auto;
        /*padding: 8%;*/
    }
    #prac-assignment .flashcard-word {
        font-size: 2.5em !important;
        text-align: center;
        padding-top: 20%;
        color: #0f6fb4;
        vertical-align: middle;
        word-break: break-word;
        scroll-padding-top: inherit;
    }
}

/* Toggle flip card css src:- https://davidwalsh.name/css-flip */

/* entire container, keeps perspective */

.flip-container {
    perspective: 1000px;
    z-index: 1;
}

.flip-container, .front, .back {
    /*width: 320px;
    height: 480px;*/
}

/* flip speed goes here */

.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

/* hide back of pane during swap */

.front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */

.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */

.back {
    transform: rotateY(180deg);
}

.flip-container.flip .flipper {
    transform: rotateY(180deg);
}

#prac-assignment .categoryItem {
    font-weight: bold !important;
    width: 80%;
    display: inline-block;
}

#prac-assignment .overflww {
    overflow: initial !important;
}

#prac-assignment .searchBtn {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -192px -9px / 600px 840px;
    display: inline-block;
    height: 25px;
    left: 0px;
    position: relative;
    top: 0px;
    width: 23px;
}

#prac-assignment #no_of_questions_to_practice {
    margin-left: 30px;
    padding-left: 10px;
    width: 260px;
    background: #f7f7f8;
}

#prac-assignment #no_of_questions_to_practice_checkbox+label.css-label1 {
    /*background-image: var(--sprite2-url)*/
}

#prac-assignment #mainQuestionView img {
    max-width: 100%;
    width: auto;
}

#prac-assignment #flashcard-btns {
    background: #f7f8f9;
    height: 50px;
    border-bottom: 1px solid #ddd;
    padding: 7px;
}

#prac-assignment #flashcard-nav {
    width: 280px;
    display: inline-block;
    padding-left: 5px;
    padding-top: 8px;
}

@media only screen and (max-width: 415px) {
    #prac-assignment #flashcard-nav {
        width: 280px;
        display: inline-block;
        padding-left: 5px;
        padding-top: 0px;
    }
}

#prac-assignment .sercFilBtnBox {
    display: none !important;
}

#prac-assignment #flashcard-btns div:nth-child(2) {
    width: 110px;
}

#test_report_modal #act-stats tbody tr:nth-child(2n + 0), #test_report_modal #student-stat tr:nth-child(2n + 0), #test_report_modal #act-stat tr:nth-child(2n + 0), #test_report_modal #act-stats-choices tr:nth-child(2n + 0), #test_report_modal #act-stats-user tr:nth-child(2n + 0), #test_report_modal #act-cat-stats-user tr, #test_report_modal #student-stat tr:nth-child(2n + 0), #test_report_modal #student-question-stat tr:nth-child(2n + 0), #test_report_modal #assignment-student-stat tr:nth-child(2n + 0) {
    background: #f7f8f9;
}

#act-cat-stats-user td:first-child {
    width: 48%;
    font-weight: bold !important;
}

#act-stats-user td:first-child {
    width: 48%;
    font-weight: bold !important;
}

#act-stats-user tr:nth-child(1) td:first-child i {
    color: #81c784;
}

#act-stats-user tr:nth-child(2) td:first-child i {
    color: #e58a31;
}

#act-stats-user tr:nth-child(3) td:first-child i {
    color: #5757e5;
}

i {
    vertical-align: bottom;
}

#test_report_modal td div.barchrtlghtbl {
    width: 100%;
}

#act-stats-user td, #test_report_modal td {
    border: 1px solid #c6c6c7;
}

.std-q-heading {
    font-weight: bold !important;
}

p.QuestionNoBorder td {
    border: 0 !important;
}

#submission .contentType h4 {
    float: none !important;
}

.timerHeading {
    font-size: 16px !important;
}

.explanation_box .body {
    max-height: 67vh !important;
    min-height: 48vh !important;
    overflow: auto;
    width: 100%;
    margin-bottom: 20px;
}

@-moz-document url-prefix() {
    @media only screen and (max-height: 767px) {
        .explanation_box .body {
            max-height: 68vh !important;
        }
    }
}

@media only screen and (max-width: 414px) {
    #prac-assignment #test_report_modal .modal-dialog {
        width: 100vw !important;
    }
}

@media only screen and (max-height: 767px) and (min-width: 414px) {
    .explanation_box .body {
        max-height: 67vh !important;
    }
    .explanation_box .my {
        max-height: 85vh !important;
    }
}

.explanation_box {
    max-height: 96vh !important;
    overflow: hidden;
    margin-bottom: 50px;
}

@media only screen and (max-width: 1199px) {
    .explanation_box {
        max-width: 920px !important;
    }
}

@media only screen and (min-height: 966px) {
    .explanation_box {
        max-height: 84vh !important;
        overflow: hidden;
        margin-bottom: 50px;
    }
}

#dragableCheckAnswer .modal-header span {
    font-size: 22px !important;
}

#dragableCheckAnswer .eleRowspop {
    padding: initial !important;
    font-size: 20px !important;
}

#dragableCheckAnswer video {
    max-width: 725px!important;
}

#dragableCheckAnswer .eleRowspoporng {
    padding: initial !important;
}

#checkAnsFooter button {
    float: right;
}

#drop_shadow_check_ans.model {
    z-index: 0;
}

.explanation_box .eleRowspop {
    /*font-size: 15px !important;*/
    padding-left: 14px;
}

@media screen and (max-height: 1000px) {
    .explanation_box {
        zoom: 0.85 !important;
        -moz-transform: translate(0, -70px) scale(0.95) !important;
    }
}

@media screen and (max-height: 850px) {
    .explanation_box {
        zoom: 0.77 !important;
        -moz-transform: translate(0, -70px) scale(0.77) !important;
    }
}

@media screen and (max-height: 750px) {
    .explanation_box {
        zoom: 0.70 !important;
        -moz-transform: translate(0, -70px) scale(0.70) !important;
    }
}

@media screen and (max-height: 700px) {
    .explanation_box {
        zoom: 0.66 !important;
        max-height: 104vh !important;
        -moz-transform: translate(0, -135px) scale(0.66) !important;
    }
    .explanation_box video {
        max-width: 560px !important;
        margin-left: 90px;
    }
    .arabic video {
        margin-right: 90px;
    }
    .explanation_box .body2 {
        max-height: 75vh !important;
    }
    div#checkAnsFooter.footer-fix {
        padding: 15px;
        height: 60px;
        position: relative;
        margin-top: 6px !important;
        margin-right: 14px;
        margin-left:30px;
        font-size:15.5px !important

    }
}

@media screen and (max-height: 650px) {
    .explanation_box {
        zoom: 0.63 !important;
        -moz-transform: translate(0, -140px) scale(0.65) !important;
        max-height: 104vh !important;
    }
    .explanation_box video {
        max-width: 560px !important;
        margin-left: 90px;
    }
    .explanation_box .body2 {
        max-height: 75vh !important;
    }
    div#checkAnsFooter.footer-fix {
        padding: 15px;
        height: 60px;
        position: relative;
        margin-top: 0px !important;
        margin-right: 14px;
        font-size:15.5px !important

    }
}

@media screen and (max-width: 990px) {
    .headbar-fix {
        display: flex;
        padding-bottom: 20px;
    }
}

.lastQuesRedirectStudent a {
    z-index: 10;
}

_:-ms-input-placeholder, :root #prac-assignment .submitAnswer {
    margin-left: -32px;
}

@media screen and (max-width: 415px) {
    /* Iphone 6/7/8 and Iphone 6/7/8 Plus*/
    #prac-assignment .forPracticeOnly {
        margin-left: 15px !important;
    }
    .studentViewQuestionContainer div .choicesText {
        width: 50vh !important;
    }
    #view-assignment #test_report_modal .modal-dialog {
        width: 100vw !important;
    }
    .studentViewQuestionContainer table tbody tr {
        display: flex;
        flex-direction: column-reverse;
    }
    .studentViewQuestionContainer table tbody td {
        margin-top: 15px;
    }
    .studentViewQuestionContainer table tbody td td {
        width: 25vh !important;
    }
    .explanation_box {
        zoom: 0.7 !important;
        height: 80vh !important;
    }
    .explanation_box .eleRowspoporng {
        padding-top: -10px;
    }
    .explanation_box .eleRowspopcrt span {
        line-height: 80%;
        font-size: 12px important;
    }
    .explanation_box .my .body {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        height: 60vh !important;
    }
    .explanation_box .my .body .cntrfon {
        margin-bottom: 40px;
    }
    .topDiv {
        margin-top: 0px;
    }
    .explanation_box .footer-fix {
        position: relative;
        top: 30px;
    }
    .footer-fix {
        padding: 0px 20px;
    }
    .iphone-view {
        position: fixed;
        top: 30vh;
        transform: translate(0px, -50px) !important;
    }
    #checkAnsFooter #next-question-button {
        position: relative;
        top: 0px;
        right:0px;
        font-size: 10.5px;
    }
    #cross-button {
        margin-left: 90% !important;
        position: absolute;
        top: 75px;
        ;
        right: -3px;
    }
    .explanation_box video {
        max-width: 100vh !important;
        margin-left: 0px !important;
    }
}