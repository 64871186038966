.stem-labs .full-wdith-blue-top,
.stem-labs .searchCrtBar,
.stem-labs table.table-hover tbody tr td .DropDivBlock span.bullets,
.stem-labs .BcrumNsteps,
.stem-labs .online-list-wrapper .heading
{
    background-color: #2a3487 !important;
}

.stem-labs .BcrumNsteps a,
.stem-labs .BcrumNsteps span{
    font-weight: bold !important;
}
/* .stem-labs .contentTypeQues h5, */
.stem-labs .navbar-nav>li>a{
    color: #2a3487;
}

.stem-labs .nav>li>a:focus:after, 
.stem-labs .nav>li>a.active:after, 
.stem-labs .navbar-nav>li>a:hover:after{
    border-color: #2a3487 
}

.stem-labs .btn-primary,
.stem-labs .submitButton,
.stem-labs .btn-saveExitBtn,
.stem-labs .btn-saveBtn,
.stem-labs .btn-saveAssign,   
.stem-labs .blubtnpops
 {
    background: linear-gradient(45deg, #2a3487, #0045a6, #2a3487) !important;
    border-color: white;
}
.stem-labs .fileContainer:hover,
.stem-labs .fileContainer:focus,
.stem-labs .fileContainer::after,
.stem-labs .chngPicBtn:hover,
.stem-labs .chngPicBtn:focus,
.stem-labs .chngPicBtn::after,
.stem-labs .submitButton:hover,
.stem-labs .submitButton:focus,
.stem-labs .submitButton::after,
.stem-labs .btn-saveBtn:hover,
.stem-labs .btn-saveBtn:focus,
.stem-labs .btn-saveBtn::after,
.stem-labs .btn-primary:hover,
.stem-labs .btn-primary:focus,
.stem-labs .btn-primary::after,
.stem-labs .btn-saveAssign:hover,
.stem-labs .btn-saveAssign:focus,
.stem-labs .btn-saveAssign::after,
.stem-labs .blubtnpops:hover,
.stem-labs .blubtnpops:focus,
.stem-labs .blubtnpops::after,
.stem-labs .btn-saveExitBtn:hover,
.stem-labs .btn-saveExitBtn:focus,
.stem-labs .btn-saveExitBtn::after{
    background: #2a3487 !important;
    border-color: white ;
    color: white !important;
}

.stem-labs .filterBlock .unSelAll {
    color: #2a3487;
}

.stem-labs #searchBox{
    height: 100%;
    border: 1px solid white;
}

.stem-labs .pageLink{
    color: #a061b8
}

.stem-labs .CourseView a, 
.stem-labs .courseInfo,
.stem-labs .addActivityBtn,
.stem-labs .addUnitBtn,
.stem-labs .btn-cancelBtn,
.stem-labs .chngPicBtn,
.stem-labs .TopBarnotification span,
.stem-labs .fileContainer {
    color: #2a3487 !important;
    border-color: #2a3487 !important; 
}

.stem-labs .custom-color-icon,
.stem-labs span.keywordTag{
    color: #2a3487 !important;
}


.stem-labs .theme-selector > .switch label,
.stem-labs .theme-selector > .logoClass.selected,
.theme-selector > .switch input:checked~label{
    border-color: #2a3487 !important;
}


.stem-labs .theme-selector > .switch input:checked~label::after,
.stem-labs .theme-selector > .switch label::after {
    background : #2a3487 !important
}