.studentNameHeadingEssay {
    color: #0f6fb4;
    font-family: 'Lato', sans-serif;
    font-size: 19px !important;
    text-transform: capitalize;
    margin-bottom: -9px;
    margin-left: 22px;
    font-weight: bold;
}

.anchorAdjustForSidePanel {
    width: 97% !important;
}

.assignmentNameHeadingEssay {
    margin-left: 22px;
    font-size: 16px !important;
    text-transform: capitalize;
    font-weight: bold;
    color: #565656;
}

span.arrowTag {
    border: solid #e4e9ec;
    background-color: #e4e9ec;
    border-width: 0 10px 10px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(+135deg);
    top: 4px;
    position: relative;
    right: -10px;
    z-index: 1;
}

span.tag {
    padding: 5px;
    padding-left: 0px;
    border-radius: 4px;
    font-size: 12px;
    /* margin-left: 11px; */
    position: relative;
    z-index: 2;
}

span.tag.submitted {
    color: #4caf50;
    background-color: #e4e9ec;
}

span.tag.notSubmitted {
    color: #f44336;
    background-color: #e4e9ec;
}

span.tag.inProgress {
    color:#2196f3;
    background-color: #e4e9ec;
}

a.Activity-toolTip.toolTipCus.tooltip-pos span.essayToolTipText {
    top: 30px;
    left: -170px;
}