.code-verify-button{
    height: fit-content;
    padding: 10px;
    font-size: 15px !important;
}
.code-verify-div{
    display: flex;
}
.code-verify-div>.form-group{
    flex: 1;
}

.teacher-signup .LogoAnch{
    width: 800px !important;
}

.theme-selector{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative
    
}
.loginPage .theme-selector > .logoClass {
    border: 1px solid;
    border-color: white;
    transition: 0.3s;
    padding: 4px;
}
.loginPage .theme-selector > .logoClass.selected {
    border-color: #253378;
}

.theme-selector > .switch {
    display: inline-block;
}

.theme-selector > .switch input {
    display: none;
}

.theme-selector > .switch label {
    display: block;
    width: 100px;
    height: 20px;
    padding: 2px;
    border-radius: 15px;
    border: 2px solid #253378;
    cursor: pointer;
    transition: 0.3s;
    position: absolute;
    top: calc(50% - 10px);
    left: calc(50% - 50px);
}

.theme-selector > .switch label::after {
    content: "";
    display: inherit;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #253378;
    transition: 0.3s;
}

.theme-selector > .switch input:checked~label {
    border-color: #253378;
}

.theme-selector > .switch input:checked~label::after {
    translate: 80px 0;
    background: #253378;
}
  