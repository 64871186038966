@import "assets/css/style.css";
@import url("assets//bootstrap/css/bootstrap.css");

body {
    margin: 0;
    padding: 0;
}

.navbar {
    border-radius: initial !important;
}

.navbar ul li a {
    margin: 0 !important;
}