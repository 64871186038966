button.DeleteButton {
    margin-top: 10px;
    color: orangered;
    border: 1px solid orangered;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

button.DeleteButton:hover {
    color: white;
    border: 1px solid white;
    background-color: orangered;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

button.deleteBtn {
    margin-left: 10px;
    color: orangered;
    border: 1px solid orangered;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

button.deleteBtn:hover {
    color: white;
    border: 1px solid white;
    background-color: orangered;
    transition: background-color 0.3s, color 0.3s, border 0.3s;
}

.mainFormBox .form-group {
    width: 100%;
}