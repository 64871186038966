.loginPage .LogoAnch{
    width: 480px;
    display: flex;
    justify-content: space-between;
}

.loginPage .logoClass{
    max-height: 60px;
    margin: 20px 0px !important;
}
.loginPage .btn-primary,
.loginPage .topBar{
    background-color: #253378;
}
.loginPage .btn-primary:hover{
    background-color: #286090;
}

.loginPage .achForm{
    color: #253378 !important;
}
.loginPage .achForm:hover{
    color: #286090 !important;
}