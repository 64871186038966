.listViewBlock table.table-hover tbody tr.customSubject .typeIcon {
    background: rgba(255, 255, 255, 0) url(/static/media/sprite-2x.425102e0.png) repeat scroll -121px -220px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.listViewBlock table.table-hover tbody tr.highSchoolEnglish .typeIcon {
    /* background: rgba(255, 255, 255, 0) url(/static/media/englishBook.png) repeat scroll -121px -220px / 500px 600px; */
    background: rgba(255, 255, 255, 0) url(../../../assets/images/englishBook.png) no-repeat scroll 100% / 100% 100%;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 1px;
    width: 52px;
}