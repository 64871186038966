.mannageQuestionSelectFildsGrid1 {
    display: grid;
    grid-template-columns: 180px 180px 180px 180px 180px;
    grid-gap: 5px;
    /* grid-template-columns: 140px 180px 180px 150px 75px; */
}

.mannageQuestionSelectFildsGrid2 {
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 155px 75px 180px 180px 180px;
    /* grid-template-columns: 140px 180px 180px 150px 75px; */
}