@media screen and (max-width: 768px) {
  .mobile-explantion-box {
    height: 100vh;
    width: 100vh;
    background-color: yellow;
  }

  #mobile-panel .modal-dialog {
    margin: 5vh auto !important;
  }

  #mobile-panel .modal-header button {
    color: white;
  }

  .modal-header .answer {
    color: white;
  }

  #heading-blue {
    background: rgb(45, 99, 180);
  }

  #heading-red {
    background: rgb(244, 67, 54);
  }

  .modal-title .answer h3 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 200;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #fff;
  }

  .modal-title .answer p {
    margin-top: 4px;
    font-weight: 100;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    color: #fff;
    font-size: 16px;
  }

  .answer h3 i {
    margin-right: 5px;
    padding-bottom: 2px;
  }

  .modal-header .answer p span {
    font-size: 16px !important;
  }

  #mobile-panel .modal-body {
    padding: 0px !important;
    font-size: 14px !important;
    font-family: Lato, sans-serif;
    font-weight: 400 !important;
  }

  #mobile-panel .modal-body .cntrfon img {
    width: 100% !important;
  }

  #mobile-panel .react-h5-audio-player {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 10px;
  }

  #mobile-panel .stars {
    margin-bottom: 10px;
  }
}
