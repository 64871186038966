
.sk-fading-bar h1{
    text-align: center;
    width:100%;
    color: white;
}

.sk-fading-bar h2{
    width:100%;
    text-align: center;
    color: white;
}


#myProgress {
    width: 100%;
    background-color: white;
}

.sk-fading-circle-main {
    position: fixed !important;
    top: 40vh;
    left: 40vw;
    width: 20vw;
    height: 40px !important;
    z-index: 1088;
}

.sk-fading-bar {
    position: relative !important;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 40px !important;
    z-index: 1088;
}



.modal-backdrop.in {
    filter: alpha(opacity=50);
    opacity: .5;
}

.progressbar-container
{
    /*!*background-color: rgb(255,2555,180);*!*/
    width: 100%;
    transition: width 200ms;
    height: 10px;
}

.progressbar-progress{
    background-color:  rgb(15,111,180) !important;
    position: relative !important;
    width: 100%;
    transition: width 200ms;
    height: 10px;
    /*margin-left:500px;*/
}

.maincolblu {
    background: #f1f9ff none repeat scroll 0 0;
    border: 1px solid #0f6fb4;
    border-radius: 12px;
    color: #505050;
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    width: 129px;
}
.btn-sta {
    background: #44a9f2 none repeat scroll 0 0;
    border: medium none;
    border-radius: 18px;
    color: #fff;
    display: block;
    font-size: 14px;
    margin: 0;
    padding: 6px 0;
    text-align: center;
    width: 80px;
}
.maincolorng {
    background: #ff7044 none repeat scroll 0 0;
    border-radius: 12px;
    color: #fff;
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    width: 129px;
}

span.subRowToggle1 {
    background: transparent var(--sprite2-url) repeat scroll -314px -176px / 488px 600px;
    display: inline-block;
    height: 27px;
    position: absolute;
    z-index: 99999;
    right: -35px;
    top: -6px;
    width: 50px;
}

span.subRowToggle1.minus {
    background: transparent var(--sprite2-url) repeat scroll -313px -232px / 488px 600px;
}
#TeacherStudentReport .view-details a img{
    height:32px;
}

#student-reports .pacbox {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 #8e8e8e;
    display: inline-block;
    float: none;
    padding: 15px;
    width: 100%;
    margin-bottom: 32px;
}

#student-reports .filterBlockss .limitFilters {
    margin: 10px 0 10px;
    overflow: hidden;
}

#student-reports .filterBlockss .typeIcons {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -466px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .filterBlockss .contentType {
    display: inline-block;
    margin-top: 10px;
}

#student-reports .float-lft {
    float: left;
}

#student-reports .filterBlockss .contentType .headsp {
    color: #0f6fb4;
    font-size: 18px;
    line-height: 29px;
}

#student-reports span.subRowToggle2 {
    background: transparent var(--sprite2-url) repeat scroll -314px -176px / 488px 600px;
    display: inline-block;
    height: 27px;
    position: absolute;
    right: 17px;
    top: 35px;
    width: 35px;
    z-index: 99999;
}

#student-reports span.subRowToggle2.minus {
    background: transparent var(--sprite2-url) repeat scroll -320px -232px / 500px 600px;
}

#student-reports .paddnone {
    padding: 0px !important;
}

#student-reports .text-alignlft {
    text-align: left !important;
}

#student-reports .filterBlockss .maincolblus {
    border: 1px solid #0f6fb4;
    border-radius: 12px;
    color: #0f6fb4;
    display: block;
    float: right;
    font-size: 14px;
    line-height: 22px;
    margin: 12px 18px 0 0;
    padding: 2px 10px;
    text-align: center;
}

#student-reports .marginrgt {
    margin-right: 0px !important;
}

#student-reports .maincencl {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    float: none;
    margin: 33px auto;
    width: 95%;
    padding: 1%;
}

#student-reports .quesAndIcon {
    padding: 0 10px;
    width: 100%;
}

#student-reports .filterBlockss {
    background: #fff !important;
    border-right: 1px solid #ddd;
    padding: 0px;
}

#student-reports .bordern {
    border: 0px solid #fff !important;
}

#student-reports .pacbox a {
    color: #555;
}

#student-reports a.smallblbx {
    color: #0f6fb4;
    text-decoration: none;
}

#student-reports .pacbox a {
    color: #555;
}

#student-reports .smallblbx {
    color: #0f6fb4 !important;
    font-size: 11px !important;
}

#student-reports .filterBlockss .typeIconsscience {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -768px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .filterBlockss .typeIconsgeometry {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -986px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .filterBlockss .typeIconshistory {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -537px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .filterBlockss .typeIconsenvironment {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -847px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .maincolblus .marginrgt{
    cursor: pointer;
}

#student-reports .view-details a img{
    height:32px;
}

#student-reports th{
    vertical-align: top;
}

#TeacherStudentReport .view-details a img{
    height:32px;
}

#TeacherStudentReport .paddnone .barchrtlghtbl{
    width: 100%;
}

#TeacherStudentReport #user-detail-dialog .modal-dialog{
    width: 70vw;
    height: 85vh;
    overflow: auto;
}

#TeacherStudentReport #student-attempt-history .table>tbody>tr>td{
    border:1px solid #555555;
}

#TeacherStudentReport #user-attempt-history-dialog .modal-dialog {
    width: 70vw;
    height: 85vh;
    overflow: auto;
}

#TeacherStudentReport .filterBoxStyle{
    background: white;
    padding: 8px;
    border: 1px solid #ddd;
    margin-top: 21px;
}

#TeacherStudentReport .studentHeading {
    font-weight: bold !important;
    padding-left: 5px;
}

#TeacherStudentReport .studentList li{
    padding: 8px;
    border-bottom: 1px solid #ddd;
}

#TeacherStudentReport .studentList li a{
    font-size: 11px !important;
    cursor: pointer;
}

#TeacherStudentReport .studentList ul {
    /* height: 47vh; */
    /*overflow: auto;*/

    min-height: 0vh;
    height: auto !important;

}

#TeacherStudentReport .searchBox input {
    width: 100%;
    margin: 10px 0;
    padding: 5px 5px;
    border: 1px solid #ddd;
}

#TeacherStudentReport #act-cat-stats-user td{
    border: 1px solid #c6c6c7;
    font-weight: bolder;
    background: #f7f8f9;
}

#TeacherStudentReport #act-cat-stats td:nth-child(2) div.paddnone, #report-view #act-cat-stats-user td:nth-child(2) div.paddnone{
    width: 100%;
}

#TeacherStudentReport table tbody tr td i {
    vertical-align: middle;
    float: right;
}


.radioElement input[type=radio].css-checkbox + label.css-label {
    padding-left: 32px;
    min-height: 35px;
    display: inline-block;
    background-repeat: no-repeat;
    background-position: -470px -92px;
    vertical-align: middle;
    cursor: pointer;
    background-size: 500px 600px;
}
.radioElement input[type=radio].css-checkbox:checked + label.css-label {
    background-position: -432px -126px;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}

.radioElement label.css-label {
    background-image: var(--sprite2-url);
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.radioElementBox1 {
    float: right;
    background: white;
    margin-top: 20px;
    margin-right: 20px;
    width: 150px;
}
.reportTypeHeading{
    font-weight:bold !important;
}

.subjectScoreHeading {
    font-weight:bold !important;
    font-size: 20px !important;
    padding: 10px;
    width: 40%;
    margin-top: -80px;
}
.subjectScoreHeadingSpan {
    font-weight:bold !important;
    font-size: 20px !important;
}
#TeacherStudentReport #student-attempt-history .indentSubCat{
    padding-left: 50px;
}

#TeacherStudentReport #filterInnerContainer {
    width: 23%;
}

#TeacherStudentReport .selectSubjectDropdown{
    float: right;
    margin-right: 20px;
    margin-top: 20px;
    margin-left: 20px;
}

#TeacherStudentReport #student-attempt-history tbody td, #TeacherStudentReport #student-attempt-history thead th{
    text-align: center;
}

#TeacherStudentReport #student-attempt-history tbody td:first-child, #TeacherStudentReport #student-attempt-history thead th:first-child{
    text-align: left;
}

#TeacherStudentReport .viewUserDetLink a{
    margin:0;
}

#TeacherStudentReport .studentNameLink{
    font-size: 15px !important;
    cursor: pointer;
}

#TeacherStudentReport .selectedStudentClass {
    background: #ddd;
}

#TeacherStudentReport span.keywordTag { display: inline-block; padding: 7px 12px; border: 1px solid #d7d7d7; border-radius: 15px; color: #0f6fb4; margin-left: 10px; margin-top: 5px; margin-left: 20px; cursor: pointer}
#TeacherStudentReport span.keywordTag span.text-cross-icon { cursor: pointer; padding: 0px 3px; }

.gradeTrackerOverviewTextUnderSubject {
    font-size: 1.1em !important;
}

.tipReportPos {
    position: relative;
    top: 8px;
    left : -2px;
    width: 25px !important;
}