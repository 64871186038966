
.langStyle select {
    height: 48px !important;
    /* color : #cccccc !important; */
}

.langStyle  {
    display : flex;
}

.unsetHeight {
    height: unset !important;
}