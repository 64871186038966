#assign-dialog .modal-dialog button.close {
    width: 20px;
    height: 20px;
    float: right;
    margin-top: 0px !important;
}

#assign-dialog .modal-dialog  .modal-title{
    float: left;
}

#assign-dialog .modal-dialog .modal-footer button {
    padding: 10px 15px;
    margin-top: 0px !important;
    width: initial;
    height: initial;
}

#assign-dialog .modal-dialog .modal-content {
    overflow: auto;
}

#assign-content .caret{
    position: relative !important;
    margin-top: 0px;
}

#assign-content{
    text-align: left;
}

.tableActions a{
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 8px 15px;
}

#assign-content td {
    vertical-align: middle;
}

#assign-content td:nth-child(3){
    width: 100px;
}

#assign-content {
    height: 65vh;
    overflow: auto;
}

.noHover:hover {
    color: #000 !important;
}