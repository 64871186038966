.maincolblu {
    background: #f1f9ff none repeat scroll 0 0;
    border: 1px solid #0f6fb4;
    border-radius: 12px;
    color: #505050;
    display: block;
    font-size: 13px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    width: 129px;
}
.btn-sta {
    background: #44a9f2 none repeat scroll 0 0;
    border: medium none;
    border-radius: 18px;
    color: #fff;
    display: block;
    font-size: 14px;
    margin: 0;
    padding: 6px 0;
    text-align: center;
    width: 80px;
}
.maincolorng {
    background: #ff7044 none repeat scroll 0 0;
    border-radius: 12px;
    color: #fff;
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    text-align: center;
    width: 129px;
}

span.subRowToggle1 {
    background: transparent var(--sprite2-url) repeat scroll -314px -176px / 488px 600px;
    display: inline-block;
    height: 27px;
    position: absolute;
    z-index: 99999;
    right: -35px;
    top: -6px;
    width: 50px;
}

span.subRowToggle1.minus {
    background: transparent var(--sprite2-url) repeat scroll -313px -232px / 488px 600px;
}
#StudentReport .view-details a img{
    height:32px;
}

#student-reports .pacbox {
    border-radius: 3px;
    box-shadow: 0 0 2px 0 #8e8e8e;
    display: inline-block;
    float: none;
    padding: 15px;
    width: 100%;
    margin-bottom: 32px;
}

#student-reports .filterBlockss .limitFilters {
    margin: 10px 0 10px;
    overflow: hidden;
}

#student-reports .filterBlockss .typeIcons {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -466px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .filterBlockss .contentType {
    display: inline-block;
    margin-top: 10px;
}

#student-reports .float-lft {
    float: left;
}

#student-reports .filterBlockss .contentType .headsp {
    color: #0f6fb4;
    font-size: 18px;
    line-height: 29px;
}

#student-reports span.subRowToggle2 {
    background: transparent var(--sprite2-url) repeat scroll -314px -176px / 488px 600px;
    display: inline-block;
    height: 27px;
    position: absolute;
    right: 17px;
    top: 35px;
    width: 35px;
    z-index: 99999;
}

#student-reports span.subRowToggle2.minus {
    background: transparent var(--sprite2-url) repeat scroll -320px -232px / 500px 600px;
}

#student-reports .paddnone {
    padding: 0px !important;
}

#student-reports .text-alignlft {
    text-align: left !important;
}

#student-reports .filterBlockss .maincolblus {
    border: 1px solid #0f6fb4;
    border-radius: 12px;
    color: #0f6fb4;
    display: block;
    float: right;
    font-size: 14px;
    line-height: 22px;
    margin: 12px 18px 0 0;
    padding: 2px 10px;
    text-align: center;
}

#student-reports .marginrgt {
    margin-right: 0px !important;
}

#student-reports .maincencl {
    background: #fff none repeat scroll 0 0;
    border: 1px solid #ccc;
    border-radius: 6px;
    float: none;
    margin: 33px auto;
    width: 95%;
    padding: 1%;
}

#student-reports .quesAndIcon {
    padding: 0 10px;
    width: 100%;
}

#student-reports .filterBlockss {
    background: #fff !important;
    border-right: 1px solid #ddd;
    padding: 0px;
}

#student-reports .bordern {
    border: 0px solid #fff !important;
}

#student-reports .pacbox a {
    color: #555;
}

#student-reports a.smallblbx {
    color: #0f6fb4;
    text-decoration: none;
}

#student-reports .pacbox a {
    color: #555;
}

#student-reports .smallblbx {
    color: #0f6fb4 !important;
    font-size: 11px !important;
}

#student-reports .filterBlockss .typeIconsscience {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -768px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .filterBlockss .typeIconsgeometry {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -986px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .filterBlockss .typeIconshistory {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -537px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .filterBlockss .typeIconsenvironment {
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -220px -847px / 500px 600px;
    display: inline-block;
    float: left;
    height: 49px;
    left: 0;
    margin-right: 6px;
    position: relative;
    top: 1px;
    width: 52px;
}

#student-reports .maincolblus .marginrgt{
    cursor: pointer;
}

#student-reports .view-details a img{
    height:32px;
}

#student-reports th{
    vertical-align: top;
}

#StudentReport .view-details a img{
    height:32px;
}

#StudentReport .paddnone .barchrtlghtbl{
    width: 100%;
}

#StudentReport #user-detail-dialog .modal-dialog{
    width: 70vw !important;
}

#StudentReport #student-attempt-history .table>tbody>tr>td{
    border:1px solid #555555;
}

#StudentReport #user-attempt-history-dialog .modal-dialog {
    width: 50vw !important;
}
.modal-dialog {margin: 150px auto !important; }

#StudentReport #act-cat-stats-user td{
    border: 1px solid #c6c6c7;
    font-weight: bolder;
    background: #f7f8f9;
}

#StudentReport #act-cat-stats td:nth-child(2) div.paddnone, #report-view #act-cat-stats-user td:nth-child(2) div.paddnone{
    width: 100%;
}