@media only screen and (min-width: 768px) {
    .topFirstBar {
        display: flex;
        align-items: center;
    }
    
    .topFirstBar .navbar{
        height: auto;
        min-height: auto;
    }

    #myNavbar .navbar-nav{
        float: none;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }
    
    #myNavbar .navbar-nav>li>a {
        padding: 0px !important;
    }

    .topFirstBar .topBarProfileIcons a{
        margin: 5px !important; 
    }
    
    .topFirstBar ul.topBarProfileIcons {
        display: flex;
        justify-content: flex-end;
        gap: 5%;
        align-items: center;
    }
    .topFirstBar .topBarProfileIcons div.profileImgLink {
        margin-top: 8px;
    }
}