/*** Additional Css for adjustment ***/

#report-view .fieldBoxFullWidth {
    width: 100% !important;
}

#report-view label.css-label1 {
    /*background-image: none;*/
}

#report-view .lastQuesRedirect {
    bottom: 50px !important;
    height: 80px  !important;
}

#report-view #mainQuestionView .answerTextArea{
    padding: 20px;
    width: 98%;
    height: 200px;
    margin: 10px;
}

#report-view .submitAnswer{
    width: 100px;
    text-align: center;
    position: absolute;
    margin-left: -50px;
}

#report-view .forPracticeOnly{
    float: left;
    margin-left: 25px;
}

#report-view .infoIcon{
    display: inline-block;
    padding: 10px;
}


#report-view .filled{
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    border-radius: 10px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

#report-view #test_report_modal .modal-dialog{
    width: 80vw !important;
}

#report-view #count_modal .modal-dialog{
    width: 400px !important;
}

#report-view #all-units div.SubjectBox {
    padding-left: 0px;
}

#report-view #all-units div.SubjectBox .limitFilters .fieldBox > div{
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

#report-view #all-units div.SubjectBox .limitFilters .fieldBox > div.active {
    border-bottom: 1px solid #ddd;
    background: #f1f9ff none repeat scroll 0 0;
    cursor: pointer;
}

#report-view #all-units div.SubjectBox .limitFilters .fieldBox div label{
    padding-left: 30px;
}

#report-view .filterBlockss .maincolblusr{
    display: inline-block !important;
}

#no_activity_selected p{
    margin: 50px;
    font-size: 20px !important;
    text-align: center;
}

#no_activity_selected p a{
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid;
    margin-top: 20px !important;
}

#report-view h3.ttme, #report-view h3.ccard, #report-view h3.sscore{
    margin: 0;
}

#report-view span.bluetxta{
    margin: 5px 0 0px;
}

#report-view a.greenbtn, #report-view a.orngbtn{
    padding: 5px 10px;
    width: 140px;
    text-align: center;
}

#report-view a.greenbtn.active{
    background: #3c873c;
    color: white;
}

#report-view a.orngbtn.active{
    background: #ff7044;
    color: white;
}

#report-view .brdgr,#report-view .brdrnam{
    height: 400px;
}

@media only screen and (min-width: 769px) {
    #report-view .brdgr,#report-view .brdrnam { max-width: 70%; margin: 0px 20% 50px 15%;}

}

#report-view  .cntrfonbig{
    padding: 25px 25px;
    min-height: 200px;
    text-align: center;
    vertical-align: middle;
    /*padding: 8%;*/
}

#report-view .brdrnam .eleRows{
    padding: 25px 0;
    margin-bottom: 0;
}

#report-view .flashcard-word {
    font-size: 2.5em !important;
    padding: 15px;
    text-align: center;
    color: #0f6fb4;
    vertical-align: middle;
    word-break: break-word;
    line-height: 215px;}

#report-view #mainQuestionView{
    margin-bottom: 80px;
}

/* Toggle flip card css src:- https://davidwalsh.name/css-flip */

/* entire container, keeps perspective */
.flip-container {
    perspective: 1000px;
}

.flip-container, .front, .back {
    /*width: 320px;
    height: 480px;*/
}

/* flip speed goes here */
.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
}

/* hide back of pane during swap */
.front, .back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */
.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
    transform: rotateY(180deg);
}

.flip-container.flip .flipper {
    transform: rotateY(180deg);
}

#report-view .categoryItem{
    font-weight: bold !important;
    width: 80%;
    display: inline-block;
}

#report-view .overflww{
    overflow: initial !important;
}

#report-view .searchBtn {
    background: rgba(0, 0, 0, 0) var(--student-sprite-url) repeat scroll -192px -9px / 600px 840px;
    display: inline-block;
    height: 25px;
    left: 0px;
    position: relative;
    top: 0px;
    width: 23px;
}

#report-view #no_of_questions_to_practice {
    margin-left: 30px;
    padding-left: 10px;
    width: 300px;
    background: #f7f7f8;
}

#report-view #no_of_questions_to_practice_checkbox + label.css-label1 {
    /*background-image: var(--sprite2-url)*/
}

#report-view #mainQuestionView img {
    max-width: 100%;
    width: auto;
}

#report-view #flashcard-btns{
    background: #f7f8f9;
    height: 50px;
    border-bottom: 1px solid #ddd;
    padding: 7px;
}

#report-view  #flashcard-nav{
    width: 350px;
    display: inline-block;
    padding-left: 5px;
    padding-top: 8px;
}

#report-view  #flashcard-nav i{
    vertical-align: bottom;
}

#report-view #flashcard-btns div:nth-child(2){
    width: 110px;
}

#activity-analysis p, #report-view #assignment-analysis p{
    width: 100% !important;
    margin: auto;
}

#student-stat td, #student-stat th, #assignment-student-stat td, #assignment-student-stat th{
    border: 1px solid #ddd;
}

#student-stat td span font { font-size: 14px !important}
#student-stat td font { font-size: 14px !important}
#student-stat td font span { font-size: 14px !important}
#student-stat td font div { font-size: 14px !important}
#student-stat td font p { font-size: 14px !important}

#act-stat td{
    border: 1px solid #ddd;
}

/*#act-stat td:first-child{
    width:80%;
}*/

#report-view #flashcard-nav a.active {
    color: #1565c0;
    font-weight: bolder !important;
}

#report-view .choice-green{
    color: #81c784;
    padding: 5px 8px;
    border: 1px solid green;
    border-radius: 5px;
}

#report-view .choice-red{
    color: #E57373;
    padding: 5px 8px;
    border: 1px solid red;
    border-radius: 5px;
}

#report-view #gray-box-container {
    background: #ffffff;
    /*margin-top: 19px;*/
}

#report-view #act-stats td:first-child,#report-view #act-stats-user td:first-child, #report-view #act-stat td:first-child, #report-view  #act-stats-choices td:first-child, #report-view  #act-stats-choices th, #report-view #act-cat-stats-user td:first-child, #report-view #student-question-stat td:first-child{
    font-weight: bolder;
}

#report-view #act-stats tbody tr:nth-child(2n + 0),#report-view  #student-stat tr:nth-child(2n + 0),#report-view  #act-stat tr:nth-child(2n + 0), #report-view  #act-stats-choices tr:nth-child(2n + 0), #report-view #act-stats-user tr:nth-child(2n + 0), #report-view #act-cat-stats-user tr, #report-view #student-stat tr:nth-child(2n + 0), #report-view #student-question-stat tr:nth-child(2n + 0), #report-view #assignment-student-stat tr:nth-child(2n + 0){
    background: #f7f8f9;
}

#report-view #contentPanel{
    background: #f7f8f9;
}

#report-view #act-cat-stats td{
    border: 1px solid #c6c6c7;
    font-weight: bolder;
    background: #f7f8f9;
}

#report-view #act-cat-stats td:nth-child(2) div.paddnone, #report-view #act-cat-stats-user td:nth-child(2) div.paddnone{
    width: 100%;
}

#report-view #act-stats td i, #report-view #act-stat td i, #report-view #act-stats-user td i{
    vertical-align: bottom;
    padding-right: 10px;
}

#report-view  #act-stats-choices td, #report-view  #act-stats-choices th, #report-view #student-question-stat td, #report-view #student-question-stat th, #report-view #act-stats td{
    border: 1px solid #c6c6c7;
}

#act-stats-choices th{
    height: 36px;
    vertical-align: middle;
    padding: 5px;
}

#report-view #user-detail-dialog .modal-dialog{
    width: 68vw;
    height: 85vh;
    overflow: auto;
}

#report-view #act-stats-user td, #report-view #act-stats-user th, #report-view #act-cat-stats-user td{
    border: 1px solid #c6c6c7;
}

#report-view #act-stats-user td:first-child, #report-view #act-cat-stats-user td:first-child{
    width: 35%;
}

#report-view #student-stat td:nth-child(2){
    width: 20%;
}

#report-view #act-stat tr:nth-child(1) td:nth-child(1) i {
    color: #64B5F6;
}

#report-view #act-stat tr:nth-child(2) td:nth-child(1) i {
    color: #81c784;
}

#report-view #act-stat tr:nth-child(3) td:nth-child(1) i {
    color: #E57373;
}

#report-view #act-stat tr:nth-child(1) td:nth-child(3) i {
    color: #81c784;
}

#report-view #act-stat tr:nth-child(2) td:nth-child(3) i {
    color: #64B5F6;
}

#report-view #act-stat tr:nth-child(3) td:nth-child(3) i {
    color: #81c784;
}

#report-view #act-stat tr:nth-child(4) td:nth-child(3) i {
    color: #E57373;
}

#report-view #act-stat tr:nth-child(8) td:nth-child(1) i {
    color: #e58a31;
}

#report-view #act-stat tr:nth-child(9) td:nth-child(1) i {
    color: #5757e5;
}

#report-view #act-stat tr:nth-child(10) td:nth-child(1) i {
    color: #e5474a;
}

#report-view #act-stats-user tr:nth-child(1) td:nth-child(1) i {
    color: #64B5F6;
}

#report-view #act-stats-user tr:nth-child(2) td:nth-child(1) i {
    color: #81c784;
}

#report-view #act-stats-user tr:nth-child(3) td:nth-child(1) i {
    color: #E57373;
}

#report-view #act-stats tr:nth-child(1) td:nth-child(1) i {
    color: #64B5F6;
}

#report-view #act-stats tr:nth-child(2) td:nth-child(1) i {
    color: #81c784;
}

#report-view #act-stats tr:nth-child(3) td:nth-child(1) i {
    color: #E57373;
}

#report-view #act-stats tr:nth-child(4) td:nth-child(1) i {
    color: #81c784;
}

#report-view #act-stats tr:nth-child(1) td:nth-child(3) i {
    color: #64B5F6;
}

#report-view #act-stats tr:nth-child(2) td:nth-child(3) i {
    color: #81c784;
}

#report-view #act-stats tr:nth-child(3) td:nth-child(3) i {
    color: #E57373;
}

#report-view #act-stats tr:nth-child(4) td:nth-child(3) i {
    color: #e58a31;
}

#report-view #act-stats tr:nth-child(9) td:nth-child(1) i {
    color: #5757e5;
}

#report-view #act-stats tr:nth-child(10) td:nth-child(1) i {
    color: #e5474a;
}

#report-view .tabActive{
    color: #1565c0;
}

#report-view .std-q-heading{
    font-weight:bold !important;
}

#report-view .reportHeading i{
    vertical-align: bottom;
}


#report-view td i{
    vertical-align: bottom;
}

#report-view .categoryItem i{
    vertical-align: bottom;
    color: #0f6fb4;
    float: right;
}

#report-view  #assignment-student-stat .spanWidth{
    display: inline-block;
    width: 100px;
}

#report-view  #assignment-student-stat .spanWidth a{
    padding-left: 15px;
}

#report-view th{
    vertical-align: top;
}

#report-view p.QuestionNoBorder td{
    border: 0;
}

#report-view .categoryItem span{
    font-style: italic !important;
    font-size: 12px !important;
}

#report-view td.questionTD td,th {
    border: 0;
}

#report-view .correctChoice{
    color: green;
    padding: 5px 10px;
    border: 1px solid green;
    border-radius: 12px;
    background: white;
}

#report-view .wrongChoice{
    color: red;
    padding: 5px 10px;
    border: 1px solid red;
    border-radius: 12px;
    background: white;
}
#report-view td.questionTD{
    border: 1px solid #555555;
}

#report-view .correctAnswerStatement span{
    color: green;
    padding: 5px 10px;
    border-radius: 12px;
    float: right;
    font-style: italic;
    font-size: 12px !important;
    font-weight: bold !important;
    margin-right: 25px;
}

#report-view .detailPrintForReport {display: inline-block; left: 5px; position: relative; width: 43px; margin-right: 10px; cursor: pointer; margin-bottom: 4px;}

#report-view .detailPrint { background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -539px; display: inline-block; height: 48px; left: 5px; position: relative; top: 22px; width: 48px; margin-right: 10px; cursor: pointer; background-size: 500px 600px; }
#report-view .detailPrint:hover { background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -118px -539px; background-size: 500px 600px; }

#report-view a.boardheadblff{  color: #000;
    float: left;
    font-size: 17px;
    margin-top: 33px;
    text-align: left;
    margin-left: 0px;
    font-size: 17px !important;
    font-weight: bold !important;
}