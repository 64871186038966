.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
@media screen and (min-width: 1500px){
  .loader {
    margin-left: 47% !important;
    margin-top: 27% !important;
    z-index: 1000000;
    /* margin: 60px auto; */
    /* font-size: 10px; */
    position: absolute;
    /* text-indent: -9999em; */
    border-top: 1.1em solid rgba(128,128,255, 0.2);
    border-right: 1.1em solid rgba(128,128,255, 0.2);
    border-bottom: 1.1em solid rgba(128,128,255, 0.2);
    border-left: 1.1em solid #0f6fb4;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;
  }
}
.loader {
  margin-left: 50%;
  margin-top: 20%;
  z-index: 1000000;
  /* margin: 60px auto; */
  /* font-size: 10px; */
  position: absolute;
  /* text-indent: -9999em; */
  border-top: 1.1em solid rgba(128,128,255, 0.2);
  border-right: 1.1em solid rgba(128,128,255, 0.2);
  border-bottom: 1.1em solid rgba(128,128,255, 0.2);
  border-left: 1.1em solid #0f6fb4;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}