.logo-container{
    padding: 2.5px 15px
}

.logo-container .LogoAnch {
    display: block;
    height: 100%;
    display: block;
    margin: 0px;
    width: fit-content;
}

.logo-container .LogoAnch .logoClass {
    display: block;
    max-height: 55px;
}
