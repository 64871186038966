/*** Additional Css for adjustment ***/

#cross-button{
    color: black;
    background-color: white;
    border-color: white;
    font-weight: bold;
    margin-top: 10px;
    font-size: 18px;
    /*margin-right:5px;*/
    margin-left: 94%;
}

.explanation_box .eleRowspoporng {
    color: #f27259;
    font-size: 20px !important;
    margin-bottom: 0;
    padding: 15px 0;
    text-align: left;
    width: 95%;
    margin-top: -30px;
    display: inherit;
    font-weight: 700 !important;
}


#prac-course .fieldBoxFullWidth {
    width: 100% !important;
}

#prac-course label.css-label1 {
    background-image: none;
}

#prac-course .lastQuesRedirect {
    bottom: 50px !important;
    height: 80px  !important;
}

#prac-course #mainQuestionView .answerTextArea{
    padding: 20px;
    width: 98%;
    height: 200px;
    margin: 10px;
}

#prac-course .submitAnswer{
    width: 100px;
    text-align: center;
    position: absolute;
    margin-left: -50px;
}

#prac-course .forPracticeOnly{
    float: left;
    margin-left: 25px;
}

#prac-course .infoIcon{
    display: inline-block;
    padding: 10px;
}


#prac-course .filled{
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    border-radius: 10px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

#prac-course #test_report_modal .modal-dialog{
    width: 80vw !important;
}

#prac-course #all-units div.SubjectBox {
    padding-left: 0px;
}

#prac-course #all-units div.SubjectBox .limitFilters .fieldBox > div{
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

#prac-course #all-units div.SubjectBox .limitFilters .fieldBox > div.active {
    border-bottom: 1px solid #ddd;
    background: #f1f9ff none repeat scroll 0 0;
    cursor: pointer;
}

#prac-course #all-units div.SubjectBox .limitFilters .fieldBox div label{
    padding-left: 30px;
}

#prac-course .filterBlockss .maincolblusr{
    display: inline-block !important;
}

#no_activity_selected p{
    margin: 50px;
    font-size: 20px !important;
    text-align: center;
}

#no_activity_selected p a{
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid;
    margin-top: 20px !important;
}

#prac-course h3.ttme, #prac-course h3.ccard, #prac-course h3.sscore{
    margin: 0;
}

#prac-course span.bluetxta{
    margin: 5px 0 0px;
}

#prac-course a.greenbtn, #prac-course a.orngbtn{
    padding: 5px 10px;
}

#prac-course a.greenbtn.active{
    background: #3c873c;
    color: white;
}

#prac-course a.orngbtn.active{
    background: #ff7044;
    color: white;
}

#prac-course .brdgr,#prac-course .brdrnam{
    height: 400px;
}

@media only screen and (min-width: 769px) {
    #prac-course .brdgr,#prac-course .brdrnam { max-width: 80%; margin: 0px 20% 50px 15%;}

}

#prac-course  .cntrfonbig{
    padding: 25px 25px;
    min-height: 200px;
    text-align: center;
    vertical-align: middle;
    /*padding: 8%;*/
}

#prac-course .brdrnam .eleRows{
    padding: 25px 0;
    margin-bottom: 0;
}

#prac-course .flashcard-word {
  font-size: 2.5em !important;
  padding: 15px;
  text-align: center;
  color: #0f6fb4;
  vertical-align: middle;
  padding-top: 82px;
    word-break: break-word;
}

#prac-course #mainQuestionView{
    margin-bottom: 80px;
}

#prac-course  .sercFilBtnBox{
    display: none !important;
}

/* Toggle flip card css src:- https://davidwalsh.name/css-flip */

/* entire container, keeps perspective */
.flip-container {
    perspective: 1000px;
    z-index: 1;
}

.flip-container, .front, .back {
    /*width: 320px;
    height: 480px;*/
}

/* flip speed goes here */
.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;

    position: relative;
}

/* hide back of pane during swap */
.front, .back {
    backface-visibility: hidden;

    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */
.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */
.back {
    transform: rotateY(180deg);
}

.flip-container.flip .flipper {
    transform: rotateY(180deg);
}

#prac-course #mainQuestionView img {
    max-width: 100%;
    width: inherit;
}

#prac-course #mainQuestionView ul {
  display: block;
  list-style-type: disc;
  -webkit-margin-before: 1em;
  -webkit-margin-after: 1em;
  -webkit-margin-start: 0px;
  -webkit-margin-end: 0px;
  -webkit-padding-start: 20px;
}

#submission .contentType h4{
    float: none !important;
}

.timerHeading{
    font-size:16px !important;
}

.explanation_box .body{
    max-height: 61vh !important;
    min-height: 48vh !important;
    overflow: auto;
    width: 100%;
    margin-bottom: 20px;
}

@media only screen and (max-height: 767px) {
    .explanation_box .body{
        max-height: 68vh !important;
    }
}

.explanation_box {
    max-height: 94vh !important;
    overflow: hidden;
    margin-bottom: 50px;
}

@media only screen and (max-width: 1199px) {
    .explanation_box {
        max-width: 920px !important;
    }
}

@media only screen and (min-height: 966px) {
    .explanation_box {
        max-height: 84vh !important;
        overflow: hidden;
        margin-bottom: 50px;
    }
}

#dragableCheckAnswer .modal-header span{
    font-size: 22px !important;
}

#dragableCheckAnswer .eleRowspop{
    padding: initial !important;
    font-size: 20px !important;
}

#dragableCheckAnswer video {
    max-width: 725px!important;
}

#dragableCheckAnswer .eleRowspoporng{
    padding: initial !important;
}
#checkAnsFooter button {
    float: right;
}

#drop_shadow_check_ans.model {
    z-index: 0;
}

.explanation_box .eleRowspop{
    /*font-size: 17px !important;*/
    padding-left: 14px;
}
@media screen and (max-height: 1000px) {
    .explanation_box{
        zoom: 0.85 !important;
        -moz-transform: translate(0, -70px) scale(0.95) !important;
    }
}
@media screen and (max-height: 850px) {
    .explanation_box{
        zoom: 0.77 !important;
        -moz-transform: translate(0, -70px) scale(0.77) !important;
    }
}

@media screen and (max-height: 750px) {
    .explanation_box{
        zoom: 0.70 !important;
        -moz-transform: translate(0, -70px) scale(0.70) !important;
    }
}

@media screen and (max-height: 700px) {
    .explanation_box{
        zoom: 0.70 !important;
        max-height : 104vh !important;
        -moz-transform: translate(0, -135px) scale(0.70) !important;
    }
    div#checkAnsFooter.footer-fix {
        padding: 15px;
        height: 60px;
        position: relative;
        margin-top: 6px !important;
        margin-right: 14px;
        margin-left:30px;
        font-size:15.5px !important

    }
}

@media screen and (max-height: 650px) {
    .explanation_box{
        zoom: 0.65 !important;
        -moz-transform: translate(0, -135px) scale(0.65) !important;
        max-height : 104vh !important;
    }
    div#checkAnsFooter.footer-fix {
        padding: 15px;
        height: 60px;
        position: relative;
        margin-top: -34px !important;
        margin-right: 14px;
        font-size:15.5px !important

    }
}

.fltrghtress {
    display: none;
}

@media screen and (max-width: 415px) { /* Iphone 6/7/8 and Iphone 6/7/8 Plus*/

    .studentViewQuestionContainer div .choicesText {
        width : 50vh !important;
    }

    #view-assignment #test_report_modal .modal-dialog{
        width: 100vw !important;
    }

    .studentViewQuestionContainer table tbody tr {
        display: flex;
        flex-direction: column-reverse;
    }

    .studentViewQuestionContainer table tbody td {
        margin-top: 15px;
    }

    .studentViewQuestionContainer table tbody td td {
        width: 25vh !important;
    }

    .explanation_box {
        zoom: 0.7 !important;
        height: 80vh !important;
    }

    .explanation_box .eleRowspoporng {
        padding-top: -10px;
    }

    .explanation_box .eleRowspopcrt span {
        line-height : 80%;
        font-size: 12px important;
    }

    .explanation_box .my .body {
        margin-bottom : 0px !important;
        margin-top: 0px !important;
        height: 60vh !important;
    }

    .explanation_box .my .body .cntrfon  {
        margin-bottom : 40px;
    }

    .topDiv {
        margin-top: 0px;
    }

    .explanation_box .footer-fix {
        position: relative;
        top: 30px;
    }


    .footer-fix {
        padding : 0px 20px !important;
    }

    .iphone-view {
        position : fixed;
        top : 30vh;
        transform: translate(0px, -50px) !important;
    }

    #checkAnsFooter #next-question-button {
        position : relative;
        top: 0px;
        right:0px;
        font-size: 10.5px;
    }

    #cross-button {
        margin-left : 90% !important;
        position : absolute;
        top: 75px;;
        right : -3px;
    }

    .explanation_box video {
        max-width: 100vh !important;
        margin-left : 0px !important;
    }

}
