    .toggle-icon {
    /* border: 1px solid black; */
    border-radius: -4%;
    border-radius: 60%;
    background: #0f6fb4;
    color: #ffff;
    /* font-size: 20px; */
    height: 24px;
    width: 24px;
    margin-left: -10px;
    padding: 2px;
    /* padding-top: 3px; */
    /* padding: 1px; */
    /* margin-top: 0px; */
    display: inline-flex;
    cursor: pointer;
    }

    .toggle-icon-left {
        float: left;
        margin-left: -4px;
    }

    .Activity-toolTip .tool-tip {
        visibility: hidden;
        width: 65px;
        background-color: #fff;
        color: #929090;
        text-align: left;
        border-radius: 3px;
        position: absolute;
        right: 41px;
        text-align: center;
        z-index: 1;
        border: 1px solid;
        margin-top: -15px;
        margin-left: -24px;
        font-size: 10px !important;
    }
    
/* .Activity-toolTip .tool-tip-sec {
    visibility: hidden;
    width: 65px;
    background-color: #fff;
    color: #929090;
    text-align: left;
    border-radius: 3px;
    position: absolute;
    right: 41px !important;
    text-align: center;
    z-index: 1;
    border: 1px solid;
    margin-top: -15px;
    margin-left: -24px;
    font-size: 10px !important;
} */

/* :lang(ar) .Activity-toolTip .tool-tip-sec {
        visibility: hidden;
        width: 65px;
        background-color: #fff;
        color: #929090;
        text-align: left;
        border-radius: 3px;
        position: absolute;
        left: 90px;
        text-align: center;
        z-index: 1;
        border: 1px solid;
        margin-top: -15px;
        margin-left: -24px;
        font-size: 10px !important;
    } */

    .Activity-toolTip:hover .tool-tip {
        visibility: visible;
    }

    /* .Activity-toolTip:hover .tool-tip-sec {
        visibility: visible;
    } */