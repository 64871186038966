.interactive-activity-container{
    height: calc(100% - 85px) !important;
}
.center-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
}
.center-container>img{
    width: 200px;
    margin: 20px;
}

.bottomRowIcon{
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
}

.bottomRowIcon>img{
    width: auto;
    max-width: 100%;
    height: 40px;
}

.footer-center-part{
    display: flex;
    justify-content: center;
    gap: 5px
}

.pie-chart{
    left: 500px;
    position: relative;
    width: fit-content;
}


.interactive-activity-container .correctChoice{
    color: green;
    padding: 5px 10px;
    border: 1px solid green;
    border-radius: 12px;
    background: white;
    width: 8em;
    text-align: center;
    position: absolute;
    right: 2em;
}

.interactive-activity-container .wrongChoice{
    color: red;
    padding: 5px 10px;
    border: 1px solid red;
    border-radius: 12px;
    background: white;
    width: 8em;
    text-align: center;
    position: absolute;
    right: 2em;
}
