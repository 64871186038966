@media only screen and (max-width:450px) {
    .searchCrtBar { 
        height: 90px;
        height: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .BcrumNsteps {
        height: 100% !important;
    }
    /* .topFirstBar {
        border-bottom: 1px #dddddd solid;
    } */
    .listMainBoxWrapper {
        border-top: 1px #dddddd solid;
    }
    .navbar-toggle { 
        position: absolute; 
    }
}