.ml-checkbox-margin {
    margin-top : 5px;
}

.subject-checkbox-margin{
    width: 100% !important;
    padding-top: 0px !important;
    display: table !important;
}

.department-selection{
    margin-left: -10px;
}
.department-selection>.subject-checkbox-margin{
    font-weight: bold !important;
    text-decoration: underline;
    text-transform: capitalize;
}

.subject-modal>.modal-dialog{
    width: 50%;
    max-width: 550px;
    margin-top: 200px !important;
}

.search-icon{
    top: -3px;
    position: relative;
}

span.keywordTag {     
    display: inline-block;
    padding: 4px 9px;
    border: 1px solid #d7d7d7;
    border-radius: 18px;
    color: #0f6fb4;
    margin-bottom: 5px;
    margin-left: 5px;
    position: relative;
    padding-right: 20px;
    font-size: 0.9em;
}
span.keywordTag span.text-cross-icon { 
    cursor: pointer;
    padding: 0px 6px;
    font-size: 19px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.subject-error{
    border: 1px solid;
    padding: 5px 10px; 
    border-radius: 5px;
    margin-left: 5px;
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;;
}

.subject-checkbox-ul{
    margin: 10px 0px;
}

.pb-0{
    padding-bottom: 0px;
}


.theme-selector{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative
    
}

.theme-selector > .logoClass {
    border: 1px solid;
    border-color: white;
    transition: 0.3s;
    padding: 4px;
    max-height: 60px;
    margin: 20px 0px !important;

}

.theme-selector > .logoClass.selected {
    border-color: #253378;
}

.theme-selector > .switch {
    display: inline-block;
}

.theme-selector > .switch input {
    display: none;
}

.theme-selector > .switch label {
    display: block;
    width: 40px;
    height: 20px;
    padding: 3px 2px;
    border-radius: 15px;
    border: 2px solid #337ab7;
    cursor: pointer;
    transition: 0.3s;
    position: unset;

}

.theme-selector > .switch label::after {
    content: "";
    display: inherit;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background: #337ab7;
    transition: 0.3s;
}

.theme-selector > .switch input:checked~label {
    border-color: #337ab7;
}

.theme-selector > .switch input:checked~label::after {
    translate: 20px 0;
    background: #337ab7;
}
  