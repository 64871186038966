/*.questionEditor modal-lg */

/*.btn btn-keybutton{*/

/*background:url(../../assets/images/minusbutton.png);*/

/*}*/

.newone .form-control {
    height: 10% !important;
    width: 85%;
}

.form-control[name="questionState"] {
    /* margin-left: -0.5%; */
    /*display: block;*/
    /* width: 19.5%; */
    /*margin-top:-18% ;*/
}

.form-control[name="questionTemplate"] {
    /* margin-left: -0.5%; */
    /*display: block;*/
    /* width: 19.5%; */
    /*margin-top:-18% ;*/
}

.listViewBlock table.table-hover tbody tr .contentTypeQues h4 {
    color: #0f6fb4;
    margin-top: 5px;
    margin-bottom: 0px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 46px;
    -webkit-line-clamp: 2;
    position: relative;
    width: 94%;
}

#dialog1 .modal-dialog .modal-content {
    height: 1%;
    margin-left: 22px;
    margin-right: 22px;
    z-index: 2;
}

#dialogCustomEntry .modal-dialog .modal-content {
    height: 100%;
    max-height: 500px;
    margin-left: 22px;
    margin-right: 22px;
    z-index: 2;
    width: 400px;
}

table.table-hover tbody tr.QuesRead .typeIcon {
    background: rgba(0, 0, 0, 0) url(/static/media/sprite-2x.425102e0.png) repeat scroll -313px -466px / 500px 600px;
    display: inline-block;
    height: 49px;
    left: 0;
    margin-right: 15px;
    position: relative;
    top: 15px;
    width: 52px;
    float: left;
}

.listViewBlock table.table-hover tbody tr .contentTypeQues {
    display: inline-block !important;
    position: relative;
    top: 5px;
    width: 68%;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    display: -webkit-box;
    min-height: 48px;
    -webkit-line-clamp: 2;
    position: relative;
    padding-right: 15px;
    height: 18%;
}

/*.mainFormBox .selectWidth .form-control {*/

/*background: transparent;*/

/*height: 40px;*/

/*border: 1px solid #e1e1e1;*/

/*color: #080808;*/

/*}*/

/*.mainFormBox .selectArrow {*/

/*background: none;*/

/*position: absolute;*/

/*right: 20px;*/

/*top: 13px;*/

/*width: 19px;*/

/*height: 16px;*/

/*display: inline-block;*/

/*background-size: 500px 600px;*/

/*}*/

/*.mainFormBox .form-group a.Activity-toolTip {*/

/*background: transparent url(/static/media/sprite-2x.425102e0.png) repeat scroll -467px -185px;*/

/*display: inline-block;*/

/*!* height: 25px; *!*/

/*width: 9.5%;*/

/*margin-top: 7px;*/

/*!* background-size: 500px 600px; *!*/

/*margin-left: 2%;*/

/*}*/

/*.listViewBlock table.table-hover tbody tr .contentTypeQues h4 span {*/

/*border-top: white;*/

/*}*/

tr th, .listViewBlock table.table-hover tbody tr {
    padding-left: 15px;
    vertical-align: middle;
    color: #555555;
    border-top: 1px solid #ddd;
}

.posRel selectWidth #newid {
    width: 30px;
}

.btn-newbutton1 {
    width: 28px;
    /*background:url(../../assets/images/minusbutton.png);*/
}

.btn-newbutton {
    width: 28px;
    /*background:url(../../assets/images/minusbutton.png);*/
}

.divEditable {
    autoplay: false;
    preload: none;
    background: transparent;
    min-height: 40px;
    border: 1px solid #e1e1e1;
    color: #000;
}

.k strong {
    font-weight: 700;
    font-size: 15px;
}

.mainFormBox1 .form-group {
    /*width: 360px !important;*/
    width: 35% !important;
    margin-top: 10px;
    float: left;
    /*margin-right: -109px;*/
    /*margin-right: -37px;*/
    margin-right: -3%;
}

.mainFormBox .selectWidth {
    /*width: 239px !important;*/
    /*width: 80% !important;*/
    position: relative;
    z-index: 2;
    float: left;
    /*margin-left: 1px;*/
}

/*.info1 .btn-primary {*/

/*color: #fff;*/

/*margin-left: 100px;*/

/*background-color: #337ab7;*/

/*border-color: #2e6da4;*/

/*}*/

/*.mainFormBox .selectWidth .form-control {*/

/*!*width: 314px;*!*/

/*!*background: transparent;*!*/

/*height: 40px;*/

/*border: 1px solid #e1e1e1;*/

/*color: #909090;*/

/*!* width: 879px; *!*/

/*}*/

.mainFormBox .selectArrow {
    background: transparent url(/static/media/sprite-2x.425102e0.png) no-repeat scroll -234px -11px;
    position: absolute;
    right: 20px;
    /*top: 13px;*/
    /*margin-right: -116px;*/
    top: 11px;
    margin-right: -7px;
    width: 19px;
    height: 16px;
    /*display: inline-block;*/
    background-size: 500px 600px;
    z-index: -1;
}

.listViewBlock table.table-hover tbody tr .contentTypeQues p {
    color: #505050;
    font-size: 12px !important;
    font-weight: bold;
}

.mainFormBox .form-group {
    margin-top: 10px;
    width: 114%;
    float: left;
    /* margin-left: -12px; */
}

.subdivision {
    /* font-size: initial !important; */
    font-family: 'Lato', sans-serif;
    font-weight: initial !important;
    font-size: 14px !important;
}

#createQuestion .modal-dialog .modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    /* overflow-y: auto; */
    /*height: 603px;*/
    /* max-height: 85vh; */
    max-height: none;
    overflow: auto !important;
    /*margin-left: -86px;*/
    /*margin-right: -75px;*/
    margin-top: -117px;
    /*-webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);*/
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
}

#math-editor .modal-dialog .modal-content {
    position: relative;
    background-color: #fff;
    /* width: 800px; */
    width: 100%;
    /* Adding height for scrollbar removal */
    /* height: 88vh; */
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    /*-webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);*/
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    margin-right: 50px;
}

/* height changes only for editor */

#editor .modal-dialog #math-editor .modal-dialog .modal-content {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 71vh;
    background-clip: padding-box;
    border: 1px solid #999;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 6px;
    outline: 0;
    /*-webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5);*/
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    margin-right: 50px;
}

#editor .modal-dialog #math-editor .modal-dialog .modal-content .modal-footer {
    display: flex;
    justify-content: flex-end;
}

#editor .modal-dialog #math-editor .modal-dialog .modal-content .modal-footer #taimoor {
    z-index: 100000;
}

#editor .modal-dialog {
    width: 67vw;
    /* Adding height for scrollbar removal */
    height: 57%;
    /* Original height */
    /* height: 67vh ; */
}

.modal-body #editorIFrame {
    width: 50%;
    /* height: 65vh; */
}

/* #editor .modal-dialog {
    width: 67vw;
    height: 67vh;
} */

#editor .modal-body {
    height: 65vh;
}

#editor #math-editor .modal-dialog {
    width: 75vw;
    height: 55vh;
    width: 90%;
}

@media screen and (min-width: 1500px) {
    #math-editor .modal-dialog .modal-content {
        position: relative;
        background-color: #fff;
        /* width: 800px; */
        width: 66%;
        /* height: 88vh; */
        background-clip: padding-box;
        border: 1px solid #999;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 6px;
        outline: 0;
        /* -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5); */
        -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
        box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
        margin-right: 50px;
    }
    #math-editor.widthFixer .modal-dialog {
        /* width:70%; */
        height: 80%;
        z-index: 2000;
        width: 90%;
        margin-left: 19% !important;
        margin-right: 0px !important;
        /* margin-top: 10px !important; */
        margin-bottom: 10px !important;
    }
    #editor .modal-dialog #math-editor.widthFixer .modal-dialog {
        height: 80%;
        z-index: 2000;
        width: 90%;
        margin-left: 19% !important;
        margin-right: 0px !important;
        /* margin-top: 10px !important; */
        margin-bottom: 10px !important;
    }
    #editor .modal-dialog #math-editor .modal-dialog .modal-content {
        position: relative;
        background-color: #fff;
        /* width: 800px; */
        width: 71%;
        /* height: 88vh; */
        background-clip: padding-box;
        border: 1px solid #999;
        border: 1px solid rgba(0, 0, 0, .2);
        border-radius: 6px;
        outline: 0;
        /* -webkit-box-shadow: 0 3px 9px rgba(0,0,0,.5); */
        -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
        box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
        margin-right: 50px;
    }
}

@media screen and (max-height: 700px) {
    #editor #math-editor .modal-body {
        height: 84vh !important;
    }
    #editor .modal-dialog #math-editor .modal-dialog .modal-content {
        height: 102vh;
    }
    #math-editor .modal-body {
        /* /* height: 90% ; */
        height: 90% !important;
        z-index: 2000
    }
}

#editor #math-editor .modal-body {
    /* original height */
    /* height: 70vh ; */
    height: 57vh;
}

#math-editor .modal-dialog {
    /* width:70%; */
    height: 80%;
    z-index: 2000;
    width: 90%;
    margin-left: 50px !important;
    margin-right: 0px !important;
    /* margin-top: 10px !important; */
    margin-bottom: 10px !important;
}

#math-editor .modal-body {
    /* Original */
    /* height: 90% ; */
    height: 60vh;
    z-index: 2000
}

/*
#math-editor .modal-footer{
    z-index: 2400
} */

.topRowHaedingAnch p {
    float: unset
}

.editor p {
    float: unset
}

.modifyButton {
    float: unset !important;
    margin-left: 4px !important;
    vertical-align: top !important;
    background-position: -381px -79px !important;
    height: 23px !important;
    width: 23px !important;
    top: 0px !important;
    cursor: pointer !important;
    /* border:1px !important; */
    margin-bottom: 2px !important;
}

.submitButton {
    padding: 10px 25px;
    margin-left: 10px;
    margin-top: 25px;
    display: inline-block;
}

.divEditable {
    background: transparent;
    min-height: 40px;
    border: 1px solid #e1e1e1;
    color: #000;
}

.grid {
    display: grid !important;
    grid-gap: 15px !important;
    /* grid-template-columns: repeat(auto-fit, 350px) !important; */
    /* grid-template-columns: repeat(auto-fit,  230px) !important; */
    grid-template-columns: 250px 250px 250px 55px;
}

.errorMessage {
    color: #a94442;
    font-size: 14px !important;
}

.no-margin {
    margin-left: 20px!important;
    margin-right: 5px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.left-div {
    float: left !important;
    width: 65% !important;
    margin-left: 10px !important;
    padding: 0px !important;
}

.right-div {
    width: 33% !important;
    float: right !important;
    margin-left: 10px !important;
    padding: 20px !important;
}

/*.mainFormBox1 .form-group{*/

/*width: 350px !important;*/

/*margin-top: 10px;*/

/*float: left;*/

/*}*/

.questionEditor {
    width: 75% !important;
}

.questionEditor .modal-body {
    padding-right: 15px;
    padding-left: 15px;
}

.mannageQuestionSelectFields {
    /*width: 239px !important;*/
    width: 75% !important;
    position: relative;
    z-index: 2;
    float: left;
}

.mannageQuestionSelectFildsGrid {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 250px 250px 250px;
    grid-template-rows: 80px;
}

.mannageQuestionSelectFildsGrid1 {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: 150px 190px 125px;
    /* grid-template-rows: 90px 70px; */
}

.newQuestionSelectField {
    /*width: 239px !important;*/
    width: 80% !important;
    position: relative;
    z-index: 2;
    float: left;
    /*margin-left: 1px;*/
}

.actionButtonclass span {
    font-size: 16px !important;
}

/* #IFRAME_DIV p strong span{

} */

.byForceLato17Question {
    display: block;
    font-family: Lato, sans-serif !important;
    font-size: 17px !important;
    font-weight: 400 !important;
}

.byForceLato17Explaination {
    display: block;
    font-family: Lato, sans-serif !important;
    font-size: 17px !important;
    /* font-weight: bold !important; */
}

.byForceLato17Explaination span {
    display: block;
    font-family: Lato, sans-serif !important;
    font-size: 17px !important;
    /* font-weight: bold !important; */
}

_:-ms-input-placeholder, :root .mannageQuestionSelectFildsGrid .form-control {
    width: 50%;
}

.space-align {
    margin-bottom: -15px;
}

.space-align2 {
    margin-bottom: 15px;
}

@media screen and (max-width: 415 px) {
    .form-control {
        font-size: 16px !important;
    }
}

.innerQuestionBox .question-and-choice p {
    margin-left: 25px;
    margin-bottom: 35px;
    margin-top: 35px;
}

/* .innerQuestionBox p{
    margin-bottom: 35px;
} */

.picker-dialog-bg {
    z-index: 20000 !important;
}

.picker-dialog {
    z-index: 20001 !important;
}