#forAdminReport .report-box span.report-box-icon {
  height: 100%;
  display: inline-block;
  padding: 7px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  /* margin-right: 10px; */
  /* float: left; */
  
  /* -webkit-box-shadow: unset;
  -moz-box-shadow: unset;
  box-shadow: unset; */
  /* transform: scale(1) */
}

#forAdminReport .report-box span.report-box-icon:active {
  -webkit-box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 25px -6px rgba(0, 0, 0, 0.75);
}

#forAdminReport .report-box.selected span.report-box-icon {
  -webkit-box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.85);
  box-shadow: 0px 0px 30px -6px rgba(0, 0, 0, 0.85);
  -webkit-transform: scale(1.2);
      -ms-transform: scale(1.2);
          transform: scale(1.2);

  color: #eee !important;
  background-color: rgb(85, 164, 240) !important;
  border: unset;
}

/* 
#forAdminReport .report-box.selected {
color: rgb(85, 164, 240) !important;
} */

#forAdminReport .report-box.selected span.report-box-text{
  color: #0f6fb4;
  font-size: 1.1em;
}

#forAdminReport .report-box span.report-box-text {
  /* font-size: 1em; */
  margin-top: 10px;
  display: inline-block;
  font-weight: bold;
  /* width: 100px; */
}

#forAdminReport .report-box {
  /* cursor: pointer; */
  /* margin-bottom: 50px; */
}

#forAdminReport .mainHeading {
  font-size: 1.35em;
  font-weight: bold;
  display: inline-block;
  color: #2E7D32;
}

#forAdminReport .mainSubHeading {
  font-size: 1.1em;
  font-weight: bold;
  display: inline-block;
  color: #0f6fb4;
  /* margin-top: 5px; */
  margin-bottom: 20px;
}

.upperBoxRows {
font-weight: bold;
}

.upperBoxRows td i {
padding-right: 10px;
}

tr:nth-child(1).upperBoxRows td:nth-child(1) i {
color: #81c784;
color: #E57373;
color: #e58a31;
color: #5757e5;
color: #e5474a;
color: #64B5F6;
}

tr:nth-child(2).upperBoxRows td:nth-child(1) i {
color: #81c784;
}

tr:nth-child(3).upperBoxRows td:nth-child(1) i {
color: #e58a31;
}

#TeacherStudentReport #forAdminReport .subjectsDropDownSetting {
  float: none;
  margin-right: 0;
  margin-top: 0;
  margin-left: 0;
}

#TeacherStudentReport #forAdminReport .classesDropDownSetting {
  float: none;
  margin-right: 0;
  margin-top: 0;
  margin-left: 0;
  display: inline-block;
  /* width: 200px; */
}

#TeacherStudentReport #forAdminReport .teachersDropDownSetting {
  float: none;
  margin-right: 0;
  margin-top: 0;
  margin-left: 0;
  display: inline-block;
  /* width: 200px; */
  margin-right: 30px; 
}

#TeacherStudentReport #forAdminReport table tbody tr td i {
  float: none;
}

#forAdminReport div.teacherTable {
  margin-top: 30px;
}

#forAdminReport span.reportHeading {
  font-size: 1.2em;
  font-weight: bold;
  display: inline-block;
  margin-right: 50px; 
}

#forAdminReport .tableUpperHeadingSettings {
  margin-top: 20px;
  margin-bottom: 10px;
}

#forAdminReport .report-box.disabled {
  color: #a1a1a1;
}


#forAdminReport .report-box.disabled i {
  color: #a1a1a1;
}

#forAdminReport .report-box.disabled span.report-box-icon {
  cursor: default;
  -webkit-box-shadow: unset;
  box-shadow: unset;
}

.backButton {
  font-size: 13px;
  padding: 5px;
  font-weight: bold;
  position: fixed;
}

.backButtonDiv {
  /* margin-bottom: 20px; */
  display: inline-block;
  float: left;
  /* margin-left: 2.625%; */
  margin-left: 20px;
}

.makeMeBolder {
  font-weight: bold !important;
}













.fixed_headers {
  /* width: 750px; */
  /* table-layout: fixed; */
  /* border-collapse: collapse; */
}
.fixed_headers th {
  /* text-decoration: underline; */
}
.fixed_headers th,
.fixed_headers td {
  /* padding: 5px; */
  /* text-align: left; */
}


.fixed_headers td:nth-child(1) {
  max-width: 1px
}
.fixed_headers td:nth-child(1),
.fixed_headers th:nth-child(1) {
  /* min-width: 150px; */
  /* min-width: 10%; */
  width: 20%;
}


.fixed_headers td:nth-child(2) {
  max-width: 1px
}
.fixed_headers td:nth-child(2),
.fixed_headers th:nth-child(2) {
  /* min-width: 120px; */
  /* min-width: 10%; */
  width: 10%;
}


.fixed_headers td:nth-child(3) {
  max-width: 1px
}
.fixed_headers td:nth-child(3),
.fixed_headers th:nth-child(3) {
  /* min-width: 150px; */
  /* min-width: 10%; */
  width: 10%;
}


.fixed_headers td:nth-child(4) {
  max-width: 1px
}
.fixed_headers td:nth-child(4),
.fixed_headers th:nth-child(4) {
  /* min-width: 130px; */
  /* min-width: 10%; */
  width: 10%;
}


.fixed_headers td:nth-child(5) {
  max-width: 1px
}
.fixed_headers td:nth-child(5),
.fixed_headers th:nth-child(5) {
  /* min-width: 150px; */
  /* min-width: 10%; */
  width: 10%;
}


.fixed_headers td:nth-child(6) {
  max-width: 1px
}
.fixed_headers td:nth-child(6),
.fixed_headers th:nth-child(6) {
  /* min-width: 180px; */
  /* min-width: 10%; */
  width: 10%;
}


.fixed_headers td:nth-child(7) {
  max-width: 1px
}
.fixed_headers td:nth-child(7),
.fixed_headers th:nth-child(7) {
  /* width: 200px; */
  /* min-width: 10%; */
  width: 10%;
}


.fixed_headers thead {
  /* background-color: #333; */
  /* color: #fdfdfd; */
  display: block;
}
.fixed_headers thead tr {
  position: relative;
  display: table;
}
.fixed_headers tbody {
  display: block;
  /* overflow: auto; */
  overflow: overlay;
  width: 100%;
  /* height: 500px; */
  max-height: 500px;
}
.fixed_headers tbody tr{
  display: table;
  width: 100%;
  min-width: 100%;
}
.fixed_headers tbody tr:nth-child(even) {
  /* background-color: #ddd; */
}
.old_ie_wrapper {
  height: 500px;
  /* width: 750px; */
  overflow-x: hidden;
  overflow-y: auto;
}
.old_ie_wrapper tbody {
  height: auto;
}














.studentTable_fixed_headers {
  /* width: 750px; */
  /* table-layout: fixed; */
  /* border-collapse: collapse; */
}
.studentTable_fixed_headers th {
  /* text-decoration: underline; */
}
.studentTable_fixed_headers th,
.studentTable_fixed_headers td {
  /* padding: 5px; */
  /* text-align: left; */
}


.studentTable_fixed_headers td:nth-child(1) {
  max-width: 1px
}
.studentTable_fixed_headers td:nth-child(1),
.studentTable_fixed_headers th:nth-child(1) {
  /* min-width: 150px; */
  /* min-width: 10%; */
  width: 20%;
}


.studentTable_fixed_headers td:nth-child(2) {
  max-width: 1px
}
.studentTable_fixed_headers td:nth-child(2),
.studentTable_fixed_headers th:nth-child(2) {
  /* min-width: 120px; */
  /* min-width: 10%; */
  width: 10%;
}


.studentTable_fixed_headers td:nth-child(3) {
  max-width: 1px
}
.studentTable_fixed_headers td:nth-child(3),
.studentTable_fixed_headers th:nth-child(3) {
  /* min-width: 150px; */
  /* min-width: 10%; */
  width: 10%;
}


.studentTable_fixed_headers td:nth-child(4) {
  max-width: 1px
}
.studentTable_fixed_headers td:nth-child(4),
.studentTable_fixed_headers th:nth-child(4) {
  /* min-width: 130px; */
  /* min-width: 10%; */
  width: 10%;
}


.studentTable_fixed_headers td:nth-child(5) {
  max-width: 1px
}
.studentTable_fixed_headers td:nth-child(5),
.studentTable_fixed_headers th:nth-child(5) {
  /* min-width: 150px; */
  /* min-width: 10%; */
  width: 10%;
}


.studentTable_fixed_headers td:nth-child(6) {
  max-width: 1px
}
.studentTable_fixed_headers td:nth-child(6),
.studentTable_fixed_headers th:nth-child(6) {
  /* min-width: 180px; */
  /* min-width: 10%; */
  width: 10%;
}



.studentTable_fixed_headers td:nth-child(7) {
  max-width: 1px
}
.studentTable_fixed_headers td:nth-child(7),
.studentTable_fixed_headers th:nth-child(7) {
  /* width: 200px; */
  /* min-width: 10%; */
  width: 10%;
}


.studentTable_fixed_headers thead {
  /* background-color: #333; */
  /* color: #fdfdfd; */
  display: block;
}
.studentTable_fixed_headers thead tr {
  position: relative;
  display: table;
}
.studentTable_fixed_headers tbody {
  display: block;
  /* overflow: auto; */
  overflow: overlay;
  width: 100%;
  /* height: 500px; */
  max-height: 500px;
}
.studentTable_fixed_headers tbody tr{
  display: table;
  width: 100%;
  min-width: 100%;
}
.studentTable_fixed_headers tbody tr:nth-child(even) {
  /* background-color: #ddd; */
}
.old_ie_wrapper {
  height: 500px;
  /* width: 750px; */
  overflow-x: hidden;
  overflow-y: auto;
}
.old_ie_wrapper tbody {
  height: auto;
}

.tooltip-pos {
  position: relative;
  top: 10px;
  width: 25px !important;
}
