/*** Additional Css for adjustment ***/

#manageUsers .detailPrintForReport {width: 43px; margin-right: 10px; cursor: pointer;}

#manageUsers .detailPrint { background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll 1px -539px; display: inline-block; height: 48px; left: 5px; position: relative; width: 48px; top: 22px; cursor: pointer; background-size: 500px 600px; }
#manageUsers .detailPrint:hover { background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -118px -539px; background-size: 500px 600px; }

.manageUsersScrollHeightIssue {
    height: calc(100% - 170px) !important;        
}
.manageUsersFilterBoxZindex {
    z-index: 1;
}