.online-list-wrapper {
    background: white;
    color: #555;
    font-family: sans-serif;
    max-width: 400px;
    min-width: 250px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    box-shadow: inset 0px 0px 5px 0px gray;
    max-height: calc(100% - 50px);
    border-radius: 5px;
}
.online-list-wrapper .rounded{
    border-radius: 5px;
}
.online-list-wrapper .heading{
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    font-weight: bold !important;
    box-shadow: inset 0px 0px 4px 0px gray;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    cursor: pointer;
}
.online-list-wrapper .heading .closeButton{
    padding: 5px;
}
.online-list-wrapper .list {
    list-style: none;
    padding: 10px 10px;
    border-radius: 5px;
    margin: 0px;
}
.online-list-wrapper .list-item {
    display: flex;
    margin: 10px;
    padding-bottom: 5px;
    padding-top: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.online-list-wrapper .list-item:last-child {
    border-bottom: none;
}
.online-list-wrapper .list-item-image {
    border-radius: 50%;
    width: 64px;
}
.online-list-wrapper .list-item-content {
    margin-left: 20px;
}
.online-list-wrapper .list-item-content h4, .online-list-wrapper .list-item-content p {
    margin: 0;
}
.online-list-wrapper .list-item-content h4 {
    margin-top: 10px;
    font-size: 18px;
}
.online-list-wrapper .list-item-content p {
    margin-top: 5px;
    color: #aaa;
}

.online-list-wrapper .profile-container{
    position: relative;
    display: inline-block;
}


.online-list-wrapper .profile-container .checkmark-overlay {
    position: absolute;
    bottom: 5px;
    right: 5px;
    background: rgba(255, 255, 255, 0.7); /* Semi-transparent white background */
    border-radius: 50%;
    padding: 5px; /* Adjust padding as needed */
}

.online-list-wrapper .profile-container .checkmark-overlay .checkmark-overlay svg {
    fill: #4caf50; /* Green color for checkmark */
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}


.online-list-wrapper .profile-container .profile-picture {
    border-radius: 50%;
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    object-fit: cover;
}


.online-list-wrapper .badge {
    position: absolute;
    bottom: 0px;
    right: 0px;
    border-radius: 50%;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.online-list-wrapper .badge svg {
    fill: white; /* White checkmark */
    width: 20px; /* Adjust size as needed */
    height: 20px; /* Adjust size as needed */
}

.online-list-wrapper .badge.b-success {
    background-color: #4caf50;
    animation: shake 0.5s ease-out;
}
.online-list-wrapper .badge.b-waiting {
    background-color: #ff9800;
    animation: spin 2s linear infinite;

}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes shake {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(-3px);
    }
    50% {
        transform: translateX(3px);
    }
    75% {
        transform: translateX(-3px);
    }
    100% {
        transform: translateX(0);
    }
}