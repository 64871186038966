/*** Additional Css for adjustment ***/

:lang(ar) {
    direction: rtl;
}

.centered-text {
    text-align: center !important;
}

.pad-ten {
    color: green;
}

.explanation_box .eleRowspoporng {
    color: #f27259;
    font-size: 20px !important;
    margin-bottom: 0;
    padding: 15px 0;
    text-align: left;
    width: 95%;
    margin-top: -53px;
    display: inherit;
    font-weight: 700 !important;
}

.react-h5-audio-player {
    margin-top: 15px;
    width: 100%;
    bottom: -4px !important;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3) !important;
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3) !important;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.3) !important;
    cursor: pointer;
}

.react-h5-audio-player div {
    cursor: pointer;
}

.react-h5-audio-player .toggle-play-button {
    background-color: #1e88e5 !important;
}

.react-h5-audio-player .toggle-play-button .pause-icon {
    box-shadow: #1e88e5 7px 0px 0px 0px inset !important;
}

.react-h5-audio-player .toggle-play-wrapper {
    flex: 1 0 40px !important;
}

.react-h5-audio-player .audio-info {
    position: relative !important;
    top: 7px !important;
}

#view-assignment .fieldBoxFullWidth {
    width: 100% !important;
}

#view-assignment label.css-label1 {
    background-image: none;
}

#view-assignment .lastQuesRedirect {
    bottom: 50px !important;
    height: 80px !important;
}

#view-assignment #mainQuestionView .answerTextArea {
    padding: 20px;
    width: 98%;
    height: 200px;
    margin: 10px;
}

#view-assignment .submitAnswer {
    width: 100px;
    text-align: center;
    position: absolute;
    margin-left: -50px;
    font-size: 12px;
}
@media only screen and (min-width: 993px) {
    #view-assignment .forPracticeOnly {
    margin-bottom: 15px;
    margin-left: 35px !important;
}
}
#view-assignment .forPracticeOnly {
    margin-bottom: 15px;
    margin-left: 25px;
}

#view-assignment .infoIcon {
    display: inline-block;
    padding: 10px;
}

#view-assignment .filled {
    background: rgba(0, 0, 0, 0.3) none repeat scroll 0 0;
    border-radius: 10px;
    content: " ";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
}

#view-assignment #test_report_modal .modal-dialog {
    width: 68vw !important;
    height: 85vh;
    overflow: auto;
    top: -55px;
}

#view-assignment #all-units div.SubjectBox {
    padding-left: 0px;
}

#view-assignment #all-units div.SubjectBox .limitFilters .fieldBox>div {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}

#view-assignment #all-units div.SubjectBox .limitFilters .fieldBox>div.active {
    border-bottom: 1px solid #ddd;
    background: #c5e1f7 none repeat scroll 0 0;
    cursor: pointer;
}

#view-assignment #all-units div.SubjectBox .limitFilters .fieldBox div label {
    padding-left: 7px;
}

#view-assignment .filterBlockss .maincolblusr {
    display: inline-block !important;
}

#no_activity_selected p {
    margin: 50px;
    font-size: 20px !important;
    text-align: center;
}

#no_activity_selected p a {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    border: 1px solid;
    margin-top: 20px !important;
}

#view-assignment h3.ttme, #view-assignment h3.ccard, #view-assignment h3.sscore {
    margin: 0;
}

#view-assignment span.bluetxta {
    margin: 5px 0 0px;
}

#view-assignment a.greenbtn, #view-assignment a.orngbtn {
    padding: 5px 10px;
    width: 140px;
    text-align: center;
}

#view-assignment a.greenbtn.active {
    background: #3c873c;
    color: white;
}

#view-assignment a.orngbtn.active {
    background: #ff7044;
    color: white;
}

@media only screen and (max-width: 415px) {
    #view-assignment .brdgr, #view-assignment .brdrnam {
        height: 350px !important;
        overflow-x: hidden;
        overflow-y: auto;
    }
}

#view-assignment .brdgr, #view-assignment .brdrnam {
    height: 400px;
}

@media only screen and (min-width: 769px) {
    #view-assignment .brdgr, #view-assignment .brdrnam {
        max-width: 80%;
        margin: 0px 20% 50px 15%;
    }
}

#view-assignment .cntrfonbig {
    padding: 25px 25px;
    min-height: 200px;
    text-align: center;
    vertical-align: middle;
    /*padding: 8%;*/
}

#view-assignment .brdrnam .eleRows {
    padding: 25px 0;
    margin-bottom: 0;
}

#view-assignment .flashcard-word {
    font-size: 2.5em !important;
    padding: 10px;
    text-align: center;
    color: #0f6fb4;
    vertical-align: middle;
    word-break: break-word;
}

#view-assignment #mainQuestionView {
    margin-bottom: 80px;
    margin-bottom: 180px;
    margin-top : 13px
}

/* Toggle flip card css src:- https://davidwalsh.name/css-flip */

/* entire container, keeps perspective */

.flip-container {
    perspective: 1000px;
    z-index: 1;
}

.flip-container, .front, .back {
    /*width: 320px;
    height: 480px;*/
}

/* flip speed goes here */

.flipper {
    transition: 0.6s;
    transform-style: preserve-3d;
    position: relative;
}

/* hide back of pane during swap */

.front, .back {
    backface-visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

/* front pane, placed above back */

.front {
    z-index: 2;
    /* for firefox 31 */
    transform: rotateY(0deg);
}

/* back, initially hidden pane */

.back {
    transform: rotateY(180deg);
}

.flip-container.flip .flipper {
    transform: rotateY(180deg);
}

#test_report_modal #act-stats tbody tr:nth-child(2n + 0), #test_report_modal #student-stat tr:nth-child(2n + 0), #test_report_modal #act-stat tr:nth-child(2n + 0), #test_report_modal #act-stats-choices tr:nth-child(2n + 0), #test_report_modal #act-stats-user tr:nth-child(2n + 0), #test_report_modal #act-cat-stats-user tr, #test_report_modal #student-stat tr:nth-child(2n + 0), #test_report_modal #student-question-stat tr:nth-child(2n + 0), #test_report_modal #assignment-student-stat tr:nth-child(2n + 0) {
    background: #f7f8f9;
}

#act-cat-stats-user td:first-child {
    width: 48%;
    font-weight: bold !important;
}

#act-stats-user td:first-child {
    width: 48%;
    font-weight: bold !important;
}

#act-stats-user tr:nth-child(1) td:first-child i {
    color: #81c784;
}

#act-stats-user tr:nth-child(2) td:first-child i {
    color: #e58a31;
}

#act-stats-user tr:nth-child(3) td:first-child i {
    color: #5757e5;
}

i {
    vertical-align: bottom;
}

#test_report_modal td div.barchrtlghtbl {
    width: 100%;
}

#act-stats-user td, #test_report_modal td {
    border: 1px solid #c6c6c7;
}

.std-q-heading {
    font-weight: bold !important;
}

#submission .contentType h4 {
    float: none !important;
}

.timerHeading {
    font-size: 16px !important;
}

.explanation_box .body {
    max-height: 67vh !important;
    min-height: 48vh !important;
    overflow: auto;
    width: 100%;
    margin-bottom: 20px;
}

.video-wrapper {
    display: flex;
    width: 100%;
    height: 0px;
    /* margin-top: 30px;
    margin-left: -50px; */
    justify-content: center;
    /* padding-top: 32px; */
}

.video-wrapper video {
    max-width: inherit !important;
}

@-moz-document url-prefix() {
    @media only screen and (max-height: 767px) {
        .explanation_box .body {
            max-height: 68vh !important;
        }
    }
}

/* @media only screen and (max-height: 767px) {
    .explanation_box .body{
        -moz-max-height: 68vh !important ;
    }
} */

.explanation_box .body div#IFRAME_DIV {
    overflow-y: scroll !important;
    overflow-x: auto !important;
    /* -webkit-overflow-scrolling:touch !important; */
    /* width: 100% !important; */
}

.explanation_box {
    max-height: 94vh !important;
    overflow: hidden;
    margin-bottom: 50px;
}

@media only screen and (max-width: 1199px) {
    .explanation_box {
        max-width: 920px !important;
    }
}

@media only screen and (min-height: 966px) {
    .explanation_box {
        max-height: 84vh !important;
        overflow: hidden;
        margin-bottom: 50px;
    }
}

#dragableCheckAnswer .modal-header span {
    font-size: 22px !important;
}

#dragableCheckAnswer .eleRowspop {
    padding: initial !important;
    font-size: 20px !important;
}

#dragableCheckAnswer video {
    max-width: 725px!important;
}

#dragableCheckAnswer .eleRowspoporng {
    padding: initial !important;
}

#checkAnsFooter button {
    float: right;
}

#drop_shadow_check_ans.model {
    z-index: 0;
}

.explanation_box .eleRowspop {
    /*font-size: 17px !important;*/
    padding-left: 14px;
}

@media screen and (max-height: 1000px) {
    .explanation_box {
        zoom: 0.85 !important;
        -moz-transform: translate(0, -70px) scale(0.95) !important;
    }
}

@media screen and (max-height: 850px) {
    .explanation_box {
        zoom: 0.77 !important;
        -moz-transform: translate(0, -70px) scale(0.77) !important;
    }
}

@media screen and (max-height: 750px) {
    .explanation_box {
        zoom: 0.70 !important;
        -moz-transform: translate(0, -70px) scale(0.70) !important;
    }
}

@media screen and (max-height: 700px) and ( min-width: 416px) {
    .explanation_box {
        zoom: 0.66 !important;
        max-height: 104vh !important;
        -moz-transform: translate(0, -135px) scale(0.66) !important;
    }
    div#checkAnsFooter.footer-fix {
        padding: 15px;
        height: 60px;
        position: relative;
        margin-top: 6px !important;
        margin-right: 14px;
        margin-left:30px;
        font-size:15.5px !important
    }
}

@media screen and (max-height: 650px) and ( min-width: 415px) {
    .explanation_box {
        zoom: 0.63 !important;
        -moz-transform: translate(0, -135px) scale(0.65) !important;
        max-height: 104vh !important;
    }
    div#checkAnsFooter.footer-fix {
        padding: 15px;
        height: 60px;
        position: relative;
        margin-top: 0px !important;
        margin-right: 14px;
        font-size:15.5px !important

    }
}

_:-ms-input-placeholder, :root #view-assignment .submitAnswer {
    margin-left: -32px;
}
@media screen and (max-width: 400px) {
    .toggle-en {
    float: right;
    /* color: white; */
    /* font-size: 1.2em; */
    font-size: 9.5px !important;
    font-weight: normal;
    margin-right: 2px;
    margin-top: 0x;
    /* position: absolute;
    right: 10px;
    top: 55px; */
    cursor: pointer;
    /* margin-top: 10px; */
}}
.toggle-en {
    float: right;
    /* color: white; */
    /* font-size: 1.2em; */
    font-size: 1.0em;
    font-weight: normal;
    margin-right: 2px;
    margin-top: 0x;
    /* position: absolute;
    right: 10px;
    top: 55px; */
    cursor: pointer;
    /* margin-top: 10px; */
}

/* @media screen and (max-width: 400px) {
    .explanation_box {
        position : relative !important;
        bottom : 4vh !important;
        padding-bottom: 10px !important;
    }

    #checkAnsFooter button {
        margin-top: 20px!important;
    }
} */

@media screen and (max-width: 415px) {
    /* Iphone 6/7/8 and Iphone 6/7/8 Plus*/
    .studentViewQuestionContainer div .choicesText {
        width: 50vh !important;
    }
    #view-assignment #test_report_modal .modal-dialog {
        width: 100vw !important;
    }
    .studentViewQuestionContainer table tbody tr {
        display: flex;
        flex-direction: column-reverse;
    }
    .studentViewQuestionContainer table tbody td {
        margin-top: 15px;
    }
    .studentViewQuestionContainer table tbody td td {
        width: 25vh !important;
    }
    .explanation_box {
        zoom: 0.7 !important;
        height: 80vh !important;
    }
    .explanation_box .eleRowspoporng {
        padding-top: -10px;
    }
    .explanation_box .eleRowspopcrt span {
        line-height: 80%;
        font-size: 12px important;
    }
    .explanation_box .my .body {
        margin-bottom: 0px !important;
        margin-top: 0px !important;
        height: 60vh !important;
    }
    .explanation_box .my .body .cntrfon {
        margin-bottom: 40px;
    }
    .topDiv {
        margin-top: 0px;
    }
    .explanation_box .footer-fix {
        position: relative;
        top: 30px;
    }
    .footer-fix {
        padding: 0px 20px;
    }
    .iphone-view {
        position: fixed;
        top: 30vh;
        transform: translate(0px, -50px) !important;
    }
    #checkAnsFooter #next-question-button {
        position: relative;
        top: 0px;
        right:0px;
        font-size: 10.5px;
    }
    #cross-button {
        margin-left: 90% !important;
        position: absolute;
        top: 75px;
        ;
        right: -3px;
    }
    .explanation_box video {
        max-width: 100vh !important;
        margin-left: 0px !important;
    }
    #view-assignment .forPracticeOnly {
        margin-left: 15px !important;
        padding-left: 0px !important;
    }
    #view-assignment .flashcard-word {
        font-size: 2.5em !important;
        padding: 10px;
        text-align: center;
        color: #0f6fb4;
        vertical-align: middle;
        scroll-padding-top: inherit;
        word-break: break-word;
    }
    #view-assignment .back .cntrfonbig {
        padding: 25px 25px;
        min-height: 200px;
        text-align: center;
        vertical-align: middle;
        padding-inline: auto;
        /* padding: 8%; */
    }
    #view-assignment .front .cntrfonbig {
        padding: 60px 25px;
        min-height: 200px;
        text-align: center;
        vertical-align: middle;
        padding-inline: auto;
        /* padding: 8%; */
    }
}