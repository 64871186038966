
/*#assignment_choices ul li input[type="radio"] {*/
    /*-webkit-appearance: radio;*/

/* .listViewBlock table.table-hover tbody tr td .reviewPend {
    display: block;
    color: #db5c35;
    border: 1px solid;
    border-radius: 10px;
    padding: 1px;
    font-size: 12px;
    width: 100%;
    text-align: center;
    margin: 2px 0px;
    white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
} */


.modal-header span {
    font-size: 23px !important;
    /*margin-right:44px;*/
}

.WHSign{
    visibility: hidden;
    width: 500px;
    background: none;
    background-color: #fff;
    color: #929090;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    z-index: 10;
    border: 1px solid;
    padding: 5px;
    display: inline-flex;
    font-size: 12px !important;
    line-height: 30px;
    margin-left: 19px;
    height: auto;
    left: -38px;
    top:-26px;

}

.WHSign.onlyForCreateAssignmentBigModal {
    line-height: 23px;
    width: 252px;
    left: 30px;
}

.actionButtonclass:hover .WHSign {
    visibility: visible;
}

.actionButtonclass{
    background: rgba(0, 0, 0, 0) var(--sprite2-url) repeat scroll -386px -272px;
    background-size: 500px 600px;

    visibility: visible;
    display: inline-block;
    width: 50px;
    margin-top: 0px;
    height: 50px;
    position: relative;
    z-index: 8/*10*/;
    margin-right: 29px;
}

.actionButtonclass.onlyForCreateAssignmentBigModalClass {
    background: rgba(0, 0, 0, 0) url(/static/media/sprite-2x.425102e0.png) repeat scroll -317px -224px;
    background-size: 415px 500px;
}

.react-datepicker-popper{
    position: absolute;
    transform: none !important;

    left: 0px;
    will-change: none !important;
}

.form-group a.Activity-toolTip {
    background: rgba(0, 0, 0, 0) url(/static/media/sprite-2x.425102e0.png) repeat scroll -467px -184px;
    display: inline-block;
    /*height: px;*/
    /* width: 5.5%; */
    width: 25px;
     margin-top: 0px;
    background-size: 500px 600px;
    margin-top: -30px;
    margin-left: 102%;
    height: 40px;
}

.form-group a.Activity-toolTip.toolTipStyleForCreateAssignmentOnly {
    float: right;
    height: 28px;
    width: 28px;
    margin-top: 0;
    margin-left: 0;
    position: absolute;
    right: 10px;
    top: 40px;
    
    /* position: relative; */
    /* left: 40px; */
    /* top: -35px;  */
    /* right: 2px; */
    /* top: 50px; */
}

.react-datepicker__input-container div input{
    border: 1px solid lightgray;
    padding: 5px 6px 6px;
    border-radius: 4px;
    margin-left: 1px;
    /*margin-right: 10px;*/
    width: 150px !important;
}


.mainFormBox h3 {
    color: #1c1c1c;
    padding: 25px 0px;
     border-bottom: 1px solid #ffffff;
    margin: 0px 20px 30px 20px;
}


.modal-footer .btn-primary {
    /* color: #fff; */
    border: 1px solid lightgray !important;
    /* padding: 5px 6px 6px !important; */
    border-radius: 4px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    /*width: 65px !important;*/
}


.react-datepicker__month-container .react-datepicker__header {
    text-align: center;
    background-color: #f0f0f0;
    border-bottom: 1px solid #aeaeae;
    border-top-right-radius: 0.3rem;
    /* padding-top: 40px; */
    position: relative;
}

.activityLogBlock .learningSlideTab .questionBlock {
    margin-top: 10px;
    height: auto;
    max-height: 450px;
    /* overflow: auto; */
    padding-bottom: 150px;
}

.activityLogBlock .learningSlideTab .questionBlock {
    padding-bottom: 0px !important;
}

.kuchBhi .react-datepicker-wrapper .react-datepicker__input-container .btn-primary {
     color: #fff;
    /*border: 1px solid lightgray !important;*/
    /*padding: 5px 6px 6px !important;*/
    /*border-radius: 4px !important;*/
    margin-left: -38px  !important;
     margin-right: -34px !important;
    /*width: 110px !important;*/
}

.dueDateSelector2 .kuchBhi .react-datepicker-wrapper .react-datepicker__input-container {
    position: relative;
    margin-left: 24px;
    display: inline-block;
}

/*.react-datepicker__input-container {*/
    /*position: absolute !important;*/
    /*display: inline-block !important;*/
/*}*/




#assignment_choices ul li input[type="radio"] {
    -webkit-appearance: radio;
    -moz-appearance:  radio;
    width: 20px;
    height: 20px;
    margin: 5px;
    vertical-align: middle;
}

#assignment_choices ul li label {
    margin: 5px;
    vertical-align: middle;
}

#assignment_choices ul li {
    display: block;
    margin: 5px;
    vertical-align: middle;
}

#assignment_choices ul {
    margin: 0px 20px 0px 20px;

}

.someClass {
    position: absolute;
    z-index: -1000;
    left: -1000px;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: auto;
    width: 100%;
    margin: -1px;
    padding: 0;
    border: 0;
}

.message {
    padding: 20px;
    font-size: 18px !important;
}

.activityLink {
    padding: 12px;
    border-radius: 8px;
    font-size: 18px !important;
    border: 1px solid #337ab7;
    margin-left: 20px;
}

.student_panel_entry_checkbox {
}

.student_panel_entry_checkbox label {
    width: 100% !important;
    line-height: initial !important;
}

.student_panel_entry_username {
    vertical-align: top;
}

.student_panel_entry_period {
    vertical-align: top;
}

#student_panel_students {
    margin-top: 20px !important;
}

#student_panel_students tr:nth-child(2) {
    margin-top: 10px !important;
}

.student_panel_search {
    border: 1px solid lightgray;
    padding: 6px;
    border-radius: 4px;
    width: 87%;
    margin-left: 15px;
}

.student_panel_due_date {
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 4px;
    margin-left: 15px;
}


.student_panel_due_date1 {
    border: 1px solid lightgray;
    padding: 5px;
    border-radius: 4px;
}


#student_table_container{
    height: 325px;
    overflow: auto;
}

#classes_container{
    height: 436px;
    overflow: auto;
}

.dueDateSelector {
    padding: 10px;
}

.dueDateSelector div {
    display: inline-block;
}

.dueDateSelector .react-datepicker-popper {
  /* left: 220px !important; */
  top: auto !important;
  left: 305px !important;
  bottom: -125px !important;
  margin-top: 0px;
}
.dueDateSelector .react-datepicker__current-month {
  display: block;
}

.dueDateSelector .react-datepicker__header {
  display: block;
}

.dueDateSelector .react-datepicker__month {
  display: block;
}

.dueDateSelector .react-datepicker__week {
  display: block;
}


.dueDateSelector2 div {
    display: inline-block;
}

.dueDateSelector2 input {
    height: 34px;
}

.kuchBhi div .react-datepicker-popper {
    /*width: 700px !important;*/
    /* left:-12px !important; */
    position: absolute !important;
}
.kuchBhi {
    position: relative;
}

.react-datepicker__day--today {
    font-weight: bolder !important;
}

.dueDateSelector2 .react-datepicker-popper {
  /* left: 162px !important; */
  /* top: -247px !important; */
  left: -42px !important;
  /* top: 10px !important; */
  top: 40px !important;
  margin-top: 0px;
}
.dueDateSelector2 .react-datepicker__current-month {
  display: block;
}

.dueDateSelector2 .react-datepicker__header {
  display: block;
}

.dueDateSelector2 .react-datepicker__month {
  display: block;
}

.dueDateSelector2 .react-datepicker__week {
  display: block;
}

.tab_link label{
    margin: 0px;
    color: #0f6fb4;
    border-radius: 0px;
    font-weight: bold !important;
    width: 100%;
    cursor: pointer;
    font-style: normal;
}
.tab_link{
    margin: 0px;
    padding: 10px;
    border-radius: 0px;
    width: 100%;
    height: auto !important;
    font-style: italic;
}

.tab_link:first-child{
    padding-top: 14px;
}

#AssignModal .modal-dialog {
    /* width: 600px; */
    margin-top: 100px !important;
}
#AssignModal .modal-body{
    padding: 10px 50px 0px 50px;
    /* padding: 20px 50px 0px 50px; */
    margin-top: -50px;
}
#period_panel{
    max-height: 28vh;
    overflow: auto;
}

.period_panel_heading{
    padding: 5px;
    margin-bottom: 12px;
}

.period_active{
    background: #0f6fb4 !important;
    color: #fff  !important;
    height: 41px;
    padding: 10px;
    border-bottom: 1px solid lightgrey;
    font-weight: bold !important;
}

[for=preserveOrder] {
    width: 100% !important;
    line-height: initial !important;
}

.valuePadding{
    padding: 5px;
}

#assignmentData table{
    margin-top: 20px;
    border: 1px solid lightgray;
}

#assignmentData table tbody tr td{
    padding: 10px;
}

.listViewBlock table.table-hover tbody tr .contentType { display: inline-block; position: relative; top: -5px; width: calc(89% - 60px) !important; max-width: 260px;}

.manageAssignmentFullViewToggle {
    width: 100vw !important;
}

/*.activityLogBlock .learningSlideTab .questionBlock{*/
    /*padding-bottom: 0px !important;*/
/*}*/

.courseAndStudent label#label1 {
    padding: 14px 0px 2px 15px;
    width: 36%;
    color: #505050;
    float: initial;
}


.courseAndStudent .selectArrow#subArrow {
    background: transparent url(/static/media/sprite-2x.f35c5f5a.png) no-repeat scroll -234px -11px;
    position: relative;
    right: 8px;
    top: -27px;
    width: 19px;
    height: 16px;
    display: inline-block;
    background-size: 500px 600px;
    z-index: 0;
    float: right;
}
.interactiveIcon{
    background: none !important;
}
.interactiveIcon img{
    width: 100%;
    display: block;
}
