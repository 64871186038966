@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }

    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
       font-size: 11px !important;
    }

    #prac-assignment .flashcard-word{
        zoom:.7;
    }

    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }

    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : landscape) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {
    .footer.text-center{display: none}  .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : landscape) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 414px)
and (max-device-width : 736px)
and (orientation : portrait) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : landscape) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 568px)
and (orientation : portrait) {
    .footer.text-center{display: none}  .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }

    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px)
and (orientation : landscape) {
    .footer.text-center{display: none} .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }
    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
        zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px)
and (orientation : portrait) {
    .footer.text-center{display: none}  .lastQuesRedirectStudent {bottom: 10px !important;}
    #view-assignment a.greenbtn, #prac-assignment a.greenbtn, #view-assignment a.orngbtn, #prac-assignment a.orngbtn {
        padding: 0px 5px !important;
        width: 90px !important;
        text-align: center !important;
        margin-left: -25px !important;
    }

    .brdgr .text-alignlft div {
        zoom:.7;
    }
    span.timerHeading, span.timerHeading + div, .ccard, .sscore, #timer, span.bluetxta, #timerHours, #timerMinutes, #timerSeconds {
        font-size: 11px !important;
    }
    #prac-assignment .flashcard-word{
       zoom:.7;
    }
    #view-assignment .sercFilBtnBox{
        display:none;
    }
    #view-assignment .lastQuesRedirectStudent a.nextQuesStudent span,
    #view-assignment .lastQuesRedirectStudent a.pause span,
    #view-assignment .lastQuesRedirectStudent a.hint span,
    #view-assignment .lastQuesRedirectStudent a.print span,
    #view-assignment .lastQuesRedirectStudent a.end span{
        font-size: 12px !important;
    }
    #view-assignment .lastQuesRedirectStudent div:first-child{
        z-index: 10;
    }
    #sidePanelContainer.activityLogBlock.DivShow.QuestionView{
        height: 100vh !important;
        top: 0px !important;
    }
    .smallHeading {
        font-size: 16px !important;
        width: 270px;
        text-align: left;
    }
    .lastQuesRedirect {
        bottom: 1px !important;
    }
    .maincolblu{
        width:110px !important;
    }
    #TeacherStudentReport #filterInnerContainer {
        width: 100vw !important;
    }
}