iframe.fr-iframe { min-height: 300px !important; }
span.fr-placeholder { padding: 10px !important; }

div.fr-popup.fr-desktop.gray-theme.fr-active{
    z-index: 3000 !important;
}
/* div.fr-popup.fr-desktop.gray-theme{
    display: none !important;
} */
/* div.fr-image-resizer.fr-active {
    display: none;
} */
/* div.fr-quick-insert.fr-visible{ top: -280px !important; } */

/* .fr-view {
    font-family: Verdana, Georgia, Serif;
    font-size: 18px;
    color: #ce1111;
} */

.rightMarginForModelButtons {
    margin-right: 10px;
}
.makeLabelBold {
    font-weight: bold !important;
}

:lang(ar) {
    direction: rtl;
}

@media screen and (max-height: 700px){
    .heightFixer{
        height: 83vh !important;
    }
    
}

.heightFixer{
    height: 60vh;
}

/* a[href="https://www.froala.com/wysiwyg-editor?k=e"] {
    display: none !important;
} */



