a {
    margin: 4px;
}

.selectedPageLink {
    padding: 10px;
    border: 1px solid #e8e8e8;
    display: inline-block;
    background-color: #44a9f2;
    margin-right: 5px;
    margin-left: 5px;
    color: white;
    text-decoration: none;
}

.selectedPageLink:hover, .selectedPageLink:visited{
    text-decoration: none;
    color: white;
}

a:visited{
    text-decoration: none;
}

.selectedPageLink:active, .selectedPageLink:visited{
    text-decoration: none;
}

.pageLink:active{
    text-decoration: none;
}

.pageLink {
    padding: 10px;
    display: inline-block;
    text-decoration: none;
}

.pageLink:hover{
    text-decoration: none;
}

.pagination {
    margin-top: 20px;
    width: 100%;

}

.paginationLinks {
    text-align: center;
    margin: auto;
}


table {
    width: 100%;
}

.paginationInfo {
    width: 300px;
    margin: auto;
    font-size: 1em;
    color: #a0a0a0;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
}

.paginationGotoInput {
    padding-top: 5px;
    padding-bottom: 5px;
    margin: auto;
    font-size: 1em;
    color: #a0a0a0;
    width: 200px;
    text-align: center;
}

.paginationGotoInput input {
    border-style: ridge;
    padding-left: 10px;
}

.emptyMessage{
    text-align: center;
}

a.selectedPageLink, a.selectedPageLink:hover, a.selectedPageLink:active, a.selectedPageLink:focus, a.selectedPageLink:visited {
    color: black !important;
    background: transparent;
    border: 0;
}