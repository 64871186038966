.footer>.container-fluid{
    height: 100%;
}
.footer>.container-fluid>.row{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.leave-footer-space{
    height: 60px;
}
.footer-logo{
    margin-bottom: 4px;
    margin-inline-start: 5px;
}