
.virtual .full-wdith-blue-top,
.virtual .searchCrtBar,
.virtual table.table-hover tbody tr td .DropDivBlock span.bullets,
.virtual .BcrumNsteps,
.virtual .online-list-wrapper .heading
{
    background-color: #744287 !important;
}
.virtual .BcrumNsteps a,
.virtual .BcrumNsteps span{
    font-weight: bold !important;
}

/* .virtual .contentTypeQues h5, */
.virtual .navbar-nav>li>a{
    color: #744287;
}

.virtual .nav>li>a:focus:after, 
.virtual .nav>li>a.active:after, 
.virtual .navbar-nav>li>a:hover:after{
    border-color: #744287 
}

.virtual .btn-primary,
.virtual .submitButton,
.virtual .btn-saveExitBtn,
.virtual .btn-saveBtn,
.virtual .btn-saveAssign,   
.virtual .blubtnpops
 {
    background: linear-gradient(45deg, #744287, #9e48bf, #744287) !important;
    border-color: white;
}
.virtual .fileContainer:hover,
.virtual .fileContainer:focus,
.virtual .fileContainer::after,
.virtual .chngPicBtn:hover,
.virtual .chngPicBtn:focus,
.virtual .chngPicBtn::after,
.virtual .submitButton:hover,
.virtual .submitButton:focus,
.virtual .submitButton::after,
.virtual .btn-saveBtn:hover,
.virtual .btn-saveBtn:focus,
.virtual .btn-saveBtn::after,
.virtual .btn-primary:hover,
.virtual .btn-primary:focus,
.virtual .btn-primary::after,
.virtual .btn-saveAssign:hover,
.virtual .btn-saveAssign:focus,
.virtual .btn-saveAssign::after,
.virtual .blubtnpops:hover,
.virtual .blubtnpops:focus,
.virtual .blubtnpops::after,
.virtual .btn-saveExitBtn:hover,
.virtual .btn-saveExitBtn:focus,
.virtual .btn-saveExitBtn::after{
    background: #65237e !important;
    border-color: white ;
    color: white !important;
}

.virtual .filterBlock .unSelAll {
    color: #744287;
}

.virtual #searchBox{
    height: 100%;
    border: 1px solid white;
}

.virtual .pageLink{
    color: #a061b8
}

.virtual .CourseView a, 
.virtual .courseInfo,
.virtual .addActivityBtn,
.virtual .addUnitBtn,
.virtual .btn-cancelBtn,
.virtual .chngPicBtn,
.virtual .TopBarnotification span,
.virtual .fileContainer {
    color: #65237e !important;
    border-color: #65237e !important; 
}

.virtual .custom-color-icon,
.virtual span.keywordTag{
    color: #65237e !important;
}

.virtual .theme-selector > .switch label,
.virtual .theme-selector > .logoClass.selected,
.theme-selector > .switch input:checked~label{
    border-color: #65237e !important;
}


.virtual .theme-selector > .switch input:checked~label::after,
.virtual .theme-selector > .switch label::after {
    background : #65237e !important
}