#loginButton {
    width: 100%;
    margin: 10px 0;
    padding: 0px;
}

#loginButton>div {
    width: 100% !important;
}

#linkAccount {
    padding: 0px;
}

#not_signed_inyxyjguigmin9 {
    padding-right: 35px !important;
    font-size: 17px !important;
}

#not_signed_in8l6g0veygugf {
    font-size: 17px !important;
}

.abcRioButtonContentWrapper {
    width: 100%;
}

#googleLogin {
    padding: 10px;
    width: 100%;
    font-size: 16px;
    background: white;
}

.google-login {
    position: absolute;
}

#linkAccount {
    padding: 10px;
    width: 250px;
    font-size: 16px;
    background: white;
}

#linkGC {
    padding: 10px;
    width: 250px;
    font-size: 16px;
    background-color: white;
}

#googleSync {
    font-size: 12px;
    background: white;
    width: 100%;
    margin-top:10px;
    padding: 8px 0px;
}

.gmailLink {
    margin-top: -28px;
}

.googleSyncImg {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 2;
    max-height: 80%;
}

.googleSyncBtn {
    /* padding: "6px 42px"; */
    float: "right";
    position: "relative";
    bottom: "3px";
    right: "4px";
    width: 100%;
    padding: 8px 0px;
    margin-top: -2px;
}

.SyncBtnDiv {
    float: right;
    margin-right: 5px;
    width: 100%;
    margin-top: -2px;
}

.syncBtnText {
    padding-left: 10px;
}

.driveBtnDiv {
    margin-right: 5px;
    width: 38%;
    margin-top: 3px;
}