#TeacherStudentReport #forCourseReport table tbody tr td i {
    float: none;
}

/* .selectSubjectDropdown .form-group, .selectSubjectDropdown .showAble {
    margin-bottom: 0px;
} */

 .boardheadblff {
     color: #505050 !important; 
     /* margin-top: -10px !important; */
}

.noHover:hover {
    color: #505050 !important;
}

 .boardheadblff1 {
    position: relative;
    /* bottom: 0px; */
    bottom: 37px;
    text-align: left;
    margin-left: 0px;
    /* font-size: 19px !important; */
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold !important;

    margin: 2px 0px;
    text-transform: capitalize;

    color: rgb(15, 111, 180);
}

.makeMyFontBig {
    font-size: 19px !important;   
    color: #2E7D32;
    margin-top: 20px;
}

.makeMyFontSmall {
    font-size: 14px !important; 
 }

 .makeMyFontSmallBigger {
     font-size: 15px !important;
 }

 .marginBottom10px {
     margin-bottom: 10px;
 }

 .marginTop {
     margin-top: calc(45px + 19px);
 }


 .fontSizeOfTableValues {
    font-size: 15px;
 }

 



/*  
 
#activity-analysis #act-stat tr:nth-child(1) td:nth-child(1) i {
    color: #64B5F6;
    color: #81c784;
    color: #E57373;
    color: #e58a31;
    color: #5757e5;
    color: #e5474a;
}





#activity-analysis #act-stat tr:nth-child(1) td:nth-child(1) i {
    color: #000;
}

#activity-analysis #act-stat tr:nth-child(1) td:nth-child(3) i {
    color: #000;
}


#activity-analysis #act-stat tr:nth-child(2) td:nth-child(1) i {
    color: #000;
}

#activity-analysis #act-stat tr:nth-child(2) td:nth-child(3) i {
    color: #000;
}


#activity-analysis #act-stat tr:nth-child(3) td:nth-child(1) i {
    color: #000;
}

#activity-analysis #act-stat tr:nth-child(3) td:nth-child(3) i {
    color: #000;
}


#activity-analysis #act-stat tr:nth-child(4) td:nth-child(1) i {
    color: #000;
}

#activity-analysis #act-stat tr:nth-child(4) td:nth-child(3) i {
    color: #000;
}


#activity-analysis #act-stat tr:nth-child(5) td:nth-child(1) i {
    color: #000;
}

#activity-analysis #act-stat tr:nth-child(5) td:nth-child(3) i {
    color: #000;
} */






























/* ::-webkit-scrollbar {
    width: 12px;
}
 
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}
 
::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
} */

